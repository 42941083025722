
// @ is an alias to /src // 引入自定义组件
// import CommonTopItems from "@/components/CommonTopItems.vue" // top
// import CommonHeaderItems from "@/components/CommonHeaderItems.vue" // 页头
// import CommonFooterItems from "@/components/CommonFooterItems.vue" // 页尾
// import SelfProgressBarEchart from '@/components/financingService/SelfProgressBarEchart.vue' // 柱状图
import EnterListBarEchartItems from '@/components/financingService/selftest/EnterListBarEchartItems.vue' // 柱状图
import SelfOrgInnovRadarChart from "@/components/financingService/selftest/SelfOrgInnovRadarChart.vue" // 综合创新力-雷达图
import SelfCopmSankeyChart from "@/components/financingService/selftest/SelfCopmSankeyChart.vue" // 竞争与合作-桑吉图
import SelfAppBarChart from "@/components/financingService/selftest/SelfAppBarChart.vue" // 专利类型分布、专利法律效力、商标法律效力-柱状图
// import SelfAppOrdBarChart from "@/components/financingService/selftest/SelfAppOrdBarChart.vue"
import SelfOpenTrendBarChart from "@/components/financingService/selftest/SelfOpenTrendBarChart.vue" // 专利公开趋势-柱状图
import SelfGlobalWorldMapChart from "@/components/financingService/selftest/SelfGlobalWorldMapChart.vue" // 专利全球布局-世界地图
import SelfPatIndexGuageChart from "@/components/financingService/selftest/SelfPatIndexGuageChart.vue" // 其他专利指标-仪表盘
import SelfPatIndexPercentGuageChart from "@/components/financingService/selftest/SelfPatIndexPercentGuageChart.vue" // 其他专利指标-仪表盘-显示百分比
import SelfPatIndexFaminChart from "@/components/financingService/selftest/SelfPatIndexFaminChart.vue" // 其他专利指标-仪表盘-显示百分比

export default {
  data() {
    return {
      noData: false, // 是否匹配到数据了-搜索建议的输入框
      activeIndex: '2', // 导航
      defulOrg: require('@/assets/images/defulorg.png'),
      isBandCompany: false, // 判断是否已绑定企业-false未绑定企业
      isJudgeLogin: false, // 判断是否登录-false未登录
      boardLoading: false, // 加载中
      bsearchOptionsArr: [], // input搜索下拉框内容
      infodata: {}, // 主体信息数组
      // keywordVal: this.$route.query.name ? this.$route.query.name : "",
      keywordVal: this.$route.query.name ? this.$route.query.name : "",
      lookKeyId: '',
      lookKeyName: this.$route.query.name ? this.$route.query.name : "",
      foldActiveNames: ['1', '2', '3', '4', '5', '6', '7', '8'],
      innovEchartDatas: [], // 综合创新力-雷达图
      copmEchartDatas: { // 竞争与合作-桑吉图
        datas: [ // data
          // {
          //   name: "采矿业"
          // },
          // {
          //   name: "电力、热力、燃气及水生产和供应业"
          // }
        ],
        links: [ // links
          // {
          //   source: "采矿业",
          //   target: "电力、热力、燃气及水生产和供应业",
          //   value: 1
          // }
        ]
      },
      appEchartDatas: [
        // 专利类型分布-柱状图
        {
          name: "发明",
          value: 179
        },
        {
          name: "实用新型",
          value: 249
        },
        {
          name: "外观设计",
          value: 86
        }
      ],
      lawEchartDatas: [
        // 专利法律效力-柱状图
        {
          name: "未授权",
          value: 185279
        },
        {
          name: "有效",
          value: 349
        },
        {
          name: "已失效",
          value: 6
        }
      ],
      mapEchartDatas: [],
      trendEchartDatas: [
        // 专利公开趋势-柱状图
        // {
        //   name: "2022",
        //   value: 330
        // }
      ],
      paabloon: false, // 专利活跃度
      paaEchartDatas: [
        // 其他专利指标-专利活跃度
        {
          value: 55,
          name: "专利活跃度"
        }
      ],
      invAuthEchartDatas: [
        // 其他专利指标-发明授权比
        {
          value: 0.6,
          name: "发明授权比"
        }
      ],
      yearEchartDatas: [
        // 其他专利指标-平均维持年限
        {
          value: 3.6,
          name: "平均维持年限"
        }
      ],
      lifeEchartDatas: [
        // 其他专利指标-平均剩余寿命
        {
          value: 11.2,
          name: "平均剩余寿命"
        }
      ],
      paaTradeEchartDatas: [
        // 其他商标指标-商标活跃度
        {
          value: 55,
          name: "商标活跃度"
        }
      ],
      regTradeEchartDatas: [
        // 其他商标指标-商标注册比
        {
          value: 0.8,
          name: "商标注册比"
        }
      ],
      brandEchartDatas: [
        // 商标法律效力
        {
          name: "申请中",
          value: 179
        },
        {
          name: "已注册",
          value: 249
        },
        {
          name: "无效",
          value: 86
        }
      ],
      isBuy: false, // 判断是否已经购买过报告  false--未购买
      isSub: false, // 判断是否已经订阅过推送  false--未订阅
      repeatInfo: "",
      agencyDefaultLogo: require('@/assets/images/default_agency_board.png') // 主要代理机构-默认logo
      // subdata: []
    }
  },
  components: {
    // CommonTopItems,
    // CommonHeaderItems,
    // CommonFooterItems,
    // SelfProgressBarEchart,
    EnterListBarEchartItems,
    SelfOrgInnovRadarChart,
    SelfCopmSankeyChart,
    SelfAppBarChart,
    SelfOpenTrendBarChart,
    SelfGlobalWorldMapChart,
    SelfPatIndexGuageChart,
    SelfPatIndexPercentGuageChart,
    SelfPatIndexFaminChart
    // SelfAppOrdBarChart
  },
  filters: {
    formatNum(val) { // 三位数字之间加分隔符
      if (!val) return "0"
      // Number.parseFloat(val).toFixed(2)
      // val.toFixed(2).toString()
      val = val.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
      return val
    },
    formatDate: function (value) { // 日期格式2023/01/01或2023/01/01 18:00:00
      let date = ''
      if (value) {
        let str = value.toString()
        if (str.length >= 14) {
          date =
            str.substring(0, 4) + '/' +
            str.substring(4, 6) + '/' +
            str.substring(6, 8) + ' ' +
            str.substring(8, 10) + ':' +
            str.substring(10, 12) + ':' +
            str.substring(12, 14)
        }
        if (str.length >= 8) {
          date =
            str.substring(0, 4) + '/' +
            str.substring(4, 6) + '/' +
            str.substring(6, 8)
        }
      }
      return date
    },
    formatDateNoZero(time) { // 日期格式-去除前面的0
      // 日期格式
      let date = ''
      if (time) {
        let str = time.toString()
        if (str.length >= 8) {
          date = str.substring(0, 4) + '/' + str.substring(4, 6) + '/' + str.substring(6, 8)
        }
        let strArray = date.split('/')
        strArray = strArray.map(function (val) {
          if (val[0] == '0') {
            val = val.slice(1)
            return val
          } else {
            return val
          }
        })
        return strArray.join('/')
      }
    }
  },
  created() {
    // this.judgeIsBand()

  },
  methods: {
    // goCreditPage(orgName) { // 查看信用-跳转查看信用页面
    //   window.open(`https://www.creditchina.gov.cn/xinyongxinxixiangqing/xyDetail.html?searchState=1&entityType=1&keyword=${orgName}`, "_blank")
    // },
    // goRankPage() { // 信用排行-跳转数据服务-排行榜-企业信用帮
    //   this.$router.push( // 携带参数-跳转页面
    //     {
    //       path: '/rankinglist/index',
    //       query: {
    //         rankType: '4' // 榜单类型
    //       }
    //     }
    //   )
    //   // let { href } = this.$router.resolve({
    //   //   path: '/selftest/report',
    //   //   query: {
    //   //     // queryType: 'org',
    //   //     orgName: companyName
    //   //   }
    //   // })
    //   // window.open(href, '_blank') // 新标签页打开
    // },
    // judgeIsBand() { // 判断登录的用户是否有绑定的企业
    //   let _this = this
    //   _this.$axios({ // 获取用户信息接口-来判断是否登录状态
    //     method: "get",
    //     url: "/service/user/userinfo", // 返回用户信息接口
    //     headers: { 'X-Requested-With': 'XMLHttpRequest' }
    //   }).then(res => {
    //     if (res.data.data.phone) { // 已登录
    //       _this.isJudgeLogin = true
    //       if (!res.data.data.institution) { // 未绑定企业
    //         _this.isBandCompany = false
    //         // _this.$message({
    //         //   message: '请绑定企业',
    //         //   type: 'warning'
    //         // })
    //       } else {
    //         _this.isBandCompany = true
    //       }
    //     } else { // 未登录
    //       _this.isJudgeLogin = false
    //     }
    //   }).catch(err => {
    //     if ((err.response && err.response.status && err.response.status == 401) || err == 401) {
    //       // 显示登录弹框
    //       // 显示登录弹框
    //       let showLogin = _this.$store.state.showLogin
    //       _this.$store.commit('changeShowLogin', !showLogin)
    //     }
    //   })
    // },
    // getrepInfo() { // 获取用户积分和商品-接口-机构创新评价报告（商品）
    //   let qsData = this.$qs.stringify({
    //     commodity_name: "机构创新评价报告"
    //   })
    //   this.$axios({
    //     method: "get",
    //     url: "/report/integral?" + qsData
    //   })
    //     .then((res) => {
    //       if (res.status == 200) {
    //         this.repeatInfo = res.data
    //         console.log(this.repeatInfo)
    //         this.rep()
    //         // this.sub()
    //       }
    //       // console.log(res)
    //     })
    //     .catch((err) => {
    //       if (err.response.status == 401) {
    //         this.$emit("getLogin")
    //       }
    //     })
    // },
    // getsubIndo() { // 获取用户积分和商品-接口-监控推送-企业院校(商品)
    //   let qsData = this.$qs.stringify({
    //     commodity_name: "监控推送-企业院校"
    //   })
    //   this.$axios({
    //     method: "get",
    //     url: "/report/integral?" + qsData
    //   })
    //     .then((res) => {
    //       if (res.status == 200) {
    //         this.repeatInfo = res.data
    //         console.log(this.repeatInfo)
    //         // this.rep()
    //         this.sub()
    //       }
    //       // console.log(res)
    //     })
    //     .catch((err) => {
    //       if (err.response.status == 401) {
    //         this.$emit("getLogin")
    //       }
    //     })
    // },
    rep() { // 判断该报告是否已购买且未到期
      let _this = this
      let qsData = _this.$qs.stringify({
        query: _this.lookKeyName,
        products: _this.repeatInfo.products
      })
      this.$axios({
        method: "get",
        url: "/report/refresh?" + qsData,
        headers: { "X-Requested-With": "XMLHttpRequest" }
      }).then((res) => {
        let { code } = res.data
        if (code == 200) {
          this.isBuy = true
        } else {
          this.isBuy = false
        }
      })
    },
    sub() { // 判断该监控推送是否已购买且未到期
      let _this = this
      let qsData = _this.$qs.stringify({
        query: _this.lookKeyName,
        products: _this.repeatInfo.products
      })
      this.$axios({
        method: "get",
        url: "/report/refresh?" + qsData,
        headers: { "X-Requested-With": "XMLHttpRequest" }
      }).then((res) => {
        if (res.data.code == 200) {
          this.isSub = true
        } else {
          this.isSub = false
        }
      })
    },
    judgeActive(data) { // 判断折叠面板是否打开
      return this.foldActiveNames.indexOf(data) // 0-打开状态，-1关闭状态
    },
    // mainInfo(keyword) { // 把机构名称转换成该机构对应的id，然后获取看板数据
    //   if (keyword == '') {
    //     this.infodata = {}
    //     return false
    //   }
    //   let _this = this
    //   let mainId = ""
    //   let qsname = this.$qs.stringify({
    //     q: 'name=' + keyword,
    //     page: 1,
    //     pageSize: 20
    //   })
    //   _this.$axios({
    //     url: "/inno/detail",
    //     method: "post",
    //     data: qsname
    //   }).then(function (res) {
    //     console.log(res.data, "name")
    //     mainId = res.data.data
    //     let qsDat = _this.$qs.stringify({
    //       id: mainId
    //       // id: 'b-4884459490529954612'
    //     })
    //     _this.getOrgInfo(qsDat)
    //   })
    // },
    openWeb() {
      if (this.infodata.enterprise_info && this.infodata.enterprise_info.company_web) {
        let url = this.infodata.enterprise_info.company_web
        if (url.indexOf('http') == -1) {
          window.open('http://' + url)
        } else {
          window.open(url)
        }
      } else {
        return false
      }
    },
    getOrgInfo(qsDat) { // 获取企业看板信息-接口
      let _this = this
      _this.boardLoading = true
      _this.$axios({
        url: "/inno/detail",
        method: "post",
        data: qsDat
      }).then(function (res) {
        console.log(res)
        _this.boardLoading = false
        let alldata = res.data
        console.log(alldata)
        if (alldata.code == 200 && alldata.data) {
          _this.infodata = alldata.data
          // for (let i in _this.infodata) {
          //   String(_this.infodata[i])
          // }

          console.log(Number(_this.infodata.paa.split("%")[0]))
          _this.paaEchartDatas[0].value = Number(
            _this.infodata.paa ? _this.infodata.paa.split("%")[0] : 0
          )
          _this.invAuthEchartDatas[0].value = Number(
            (_this.infodata.igr).split('%')[0] / 100
          )
          _this.yearEchartDatas[0].value = Number(_this.infodata.pls)
          console.log(_this.yearEchartDatas[0].value)
          _this.lifeEchartDatas[0].value = Number(_this.infodata.prl)
          _this.paaTradeEchartDatas[0].value = Number(
            (_this.infodata.traa).split("%")[0]
          )
          _this.regTradeEchartDatas[0].value = Number(_this.infodata.trrr).toFixed(2)
          _this.lawEchartDatas[0].value = _this.infodata.ls1 ? _this.infodata.ls1 + 0 : 0
          _this.lawEchartDatas[1].value = _this.infodata.ls2 ? _this.infodata.ls2 + 0 : 0
          _this.lawEchartDatas[2].value = _this.infodata.ls3 ? _this.infodata.ls3 + 0 : 0
          _this.appEchartDatas[0].value = _this.infodata.pt1 ? _this.infodata.pt1 + 0 : 0
          _this.appEchartDatas[1].value = _this.infodata.pt2 ? _this.infodata.pt2 + 0 : 0
          _this.appEchartDatas[2].value = _this.infodata.pt3 ? _this.infodata.pt3 + 0 : 0
          _this.brandEchartDatas[0].value = _this.infodata.trls1 ? _this.infodata.trls1 + 0 : 0
          _this.brandEchartDatas[1].value = _this.infodata.trls2 ? _this.infodata.trls2 + 0 : 0
          _this.brandEchartDatas[2].value = _this.infodata.trls3 ? _this.infodata.trls3 + 0 : 0
          console.log(_this.brandEchartDatas)
          _this.innovEchartDatas = [
            _this.infodata.score.inno_prod * 10,
            _this.infodata.score.inno_tran * 10,
            _this.infodata.score.inno_tech * 10,
            _this.infodata.score.inno_mark * 10,
            _this.infodata.score.inno_dynamic * 10,
            _this.infodata.score.inno_infl * 10,
            _this.infodata.score.inno_prot * 10,
            _this.infodata.score.inno_activity * 10
          ]
          // console.log(_this.infodata.appletCode)

          let datas = _this.infodata.competes
          let links = _this.infodata.links
          _this.copmEchartDatas = { datas, links }

          console.log(_this.copmEchartDatas)
          let mapArr = []
          _this.infodata.globs.forEach((item) => {
            mapArr.push({ name: item.name, vlaue: 1 })
          })
          console.log(_this.infodata.globs)
          _this.mapEchartDatas = mapArr
          console.log(_this.mapEchartDatas, "mapEchartDatas。")
          let trendArr = []
          if (_this.infodata.models && _this.infodata.models.ad_year) {
            _this.infodata.models.ad_year.forEach((item) => {
              trendArr.push({ name: item.name, value: item.count })
              // console.log(item.name)
            })
          }

          _this.trendEchartDatas = trendArr
          console.log(_this.trendEchartDatas, '_this.trendEchartDatas')
          _this.infodata.glob.sort(_this.down("count"))
          console.log(_this.infodata.glob, "glob")
        } else {
          _this.infodata = ''
          console.log(_this.infodata)
        }
      }).catch(err => {
        _this.boardLoading = false
        _this.infodata = ''
        console.log(err)
      })
    },
    foldHandleChange() { },
    down(name) {
      return function (obj1, obj2) {
        let val1 = obj1[name]

        let val2 = obj2[name]

        return val2 - val1
      }
    },
    toReportcompany(type) { // 生成报告/订阅推送--跳转到相应的页面
      let companyName = this.lookKeyName
      if (type == 'rep') { // 质量报告
        let { href } = this.$router.resolve({
          path: '/enterprise/report',
          query: {
            // queryType: 'org',
            queryType: '企业/院校',
            orgName: companyName
          }
        })
        window.open(href, '_blank')
      } else if (type == 'sub') { // 监控追踪
        //   this.$router.push( // 携带参数-跳转页面
        //   {
        //     path: '/monitorpush/subscribepush',
        //     query: {
        //       queryType: 'org',
        //       orgName: companyName
        //     }
        //   }
        // )
        let { href } = this.$router.resolve({
          path: '/monitor/query',
          query: {
            queryType: 'org',
            orgName: companyName
          }
        })
        window.open(href, '_self') // 当前页面打开
      }
    },

    querySearch(queryString, cb) {
      // 主体列表
      console.log(queryString, cb)
      let _this = this
      let qsDat = _this.$qs.stringify({
        q: 'name=' + queryString,
        page: 1,
        pageSize: 20

      })
      _this
        .$axios({
          method: "post",
          url: "/inno/name/list?" + qsDat

        })
        .then(function (res) {
          console.log(res.data.data)
          let searchArr = []
          if (res.data.code == 200) {
            let allSearcharr = res.data.data
            console.log(allSearcharr)
            if (allSearcharr && allSearcharr.innovators && allSearcharr.innovators.length > 0) {
              allSearcharr.innovators.forEach((item) => {
                searchArr.push({ value: item.name, id: item.id })
              })
            } else {
              searchArr = []
            }
          } else {
            searchArr = []
          }

          _this.noData = false
          if (searchArr.length === 0) {
            searchArr = [{ default: '未找到结果' }]
            _this.noData = true
          }
          console.log(searchArr, "bsearchOptionsArr")
          let restaurants = searchArr
          cb(restaurants)
        })

      // let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
    },
    boardhandleSelect(item) { // 企业名称-下拉列表选择
      console.log('下拉选择')
      console.log(item)
      let _this = this
      _this.lookKeyId = item.id
      _this.lookKeyName = item.value
    },
    lookSearchBtn() { // 点击查看-操作
      let _this = this

      _this.isJudgeLogin = true
      let qsDat = _this.$qs.stringify({
        id: _this.lookKeyId
      })
      // console.log('查看==' + qsDat)
      // _this.mainInfo(_this.keywordVal)
      _this.getOrgInfo(qsDat)
      // _this.getrepInfo()
      // _this.getsubIndo()
    },
    lookPdf() { // 查看报告操作
      let _this = this
      let qsData = _this.$qs.stringify({
        corporateName: _this.lookKeyName,
        templateName: '小程序模板',
        sort: '',
        state: 0,
        page: 1,
        pageSize: 10
      })
      _this.$axios({
        method: 'post',
        url: '/report/list',
        data: qsData
      }).then(function (res) {
        console.log(res)
        if (res.data.code == 200) {
          window.open(res.data.data[0].pdf_info.url, "_blank")
        }
      })
    },
    goPantentListPage(val, num) { // 点击跳转企业版专利列表页-先判断是否登录
      if (num > 0) { // 数量大于0，则可点击跳转列表页
        window.open(this.urlUser + '/db/list/1?q=' + val, '_self')
      }
    },
    goBrandListPage(val, num) { // 点击跳转商标列表页-先判断是否登录
      if (num > 0) {
        // 跳转页面
        let { href } = this.$router.resolve({
          path: '/brand/list',
          query: { inputVal: val }
        })
        // window.open(href, "_blank") // 跳转新页面打开-实现方式
        window.location.href = href // 覆盖页面打开-实现方式
      }
    }
  },
  beforeDestory() {
  }
}
