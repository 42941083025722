<template>
  <!-- 万象监控-企业/院校-详情 -->
  <div class="pagebox">
    <!-- top -->
    <!-- <CommonTopItems></CommonTopItems> -->
    <!-- 页头 -->
    <!-- <CommonHeaderItems :activeIndex="activeIndex"></CommonHeaderItems> -->
    <!-- 面包屑导航 -->

    <!-- 内容部分 -->
    <div class="page-listcon">
      <!-- 企业看板-内容 -->
      <div class="board-borgmain">
        <!-- 搜索内容 -->
        <div class="borgmain-bsearch">
          <!-- <el-input
            class="bsearch-inputval"
            v-model="keywordVal"
            placeholder="企业/院校名称"
          ></el-input> -->
          <div>
            <!-- <el-autocomplete
            class="bsearch-inputval"
            v-model="keywordVal"
            :fetch-suggestions="querySearch"
            placeholder="企业/院校名称"
            @select="boardhandleSelect"
              :trigger-on-focus="false"
          ></el-autocomplete> -->
            <el-autocomplete
              class="bsearch-inputval"
              v-model="keywordVal"
              :popper-class="noData ? 'platform-auto-complete' : ''"
              :fetch-suggestions="querySearch"
              placeholder="企业/院校名称"
              @select="boardhandleSelect"
              :trigger-on-focus="false"
              :debounce="0"
            >
              <!-- debounce-输入建议的去抖延时 -->
              <!-- 无匹配数据-显示 -->
              <template v-if="noData" slot-scope="{ item }">
                <div class="default">{{ item.default }}</div>
              </template>
            </el-autocomplete>
          </div>
          <!-- 搜索内容-无匹配数据时-“开始自测”不可点 -->
          <el-button class="bsearch-btnstyle bsearch-lookbtn" :disabled="noData" @click="lookSearchBtn">搜索</el-button>
          <!-- <div class="borgmain-versions">数据版本：{{ dataVersionDate | formatDate }}</div> -->
        </div>
        <!-- v-if="infodata.length == 0 || !this.$store.state.logininfo.loginBloon" -->
        <template v-if="JSON.stringify(infodata) == '{}'">
          <div class="borgmain-deful" v-loading="boardLoading">
            <div class="deful-btns1">
              <div>基本信息</div>
              <div>知识产权质量</div>
              <div>技术信息</div>
              <div>创新人才</div>
              <div>竞争与合作</div>
              <div>代理机构</div>
              <div>专利申请</div>
              <div>商标申请</div>
            </div>
            <div class="defulimg-bgimgbox">
              <img src="@/assets/images/bt_orgbg_default.png" alt="" />
            </div>
          </div>
        </template>
        <template v-else>
          <div>
            <!-- 内容-头部显示 -->
            <div class="borgmain-bhead" v-loading="boardLoading">
              <!-- 左侧头部详细信息 -->
              <div class="bhead-bleftinfo">
                <div class="bhead-binfo">
                  <div class="binfo-blogo">
                    <img v-if="infodata.logo" :src="infodata.logo" alt="" class="blogo-logoimg" />
                    <div class="firimgbox-firlogo" v-else-if="infodata.name">
                      {{ infodata.name.slice(0, 4) }}
                    </div>
                    <img v-else class="blogo-logoimg" :src="!!defulOrg" />
                  </div>
                  <div class="binfo-infobox">
                    <div class="binfo-borgname">
                      <span class="borgname-bnameres">{{ !!infodata.name ? infodata.name : '--' }}</span>
                      <span
                        class="borgname-btag btag-bgorange"
                        v-if="!!infodata.enterprise_info && !!infodata.enterprise_info.company_status"
                      >
                        {{ infodata.enterprise_info.company_status }}
                      </span>
                      <span class="borgname-btag btag-bggreen" v-if="infodata.gxjs == 1">高新技术企业</span>
                      <span
                        class="borgname-btag btag-bgred"
                        v-if="!!infodata.enterprise_info && !!infodata.enterprise_info.company_stock_code"
                      >
                        {{ infodata.enterprise_info.company_stock_code }}
                      </span>
                    </div>
                    <div class="binfo-bothername">
                      <div
                        class="bothername-bused"
                        v-if="!!infodata.enterprise_info && !!infodata.enterprise_info.company_former_name"
                      >
                        <span class="bused-btle">曾用名：</span>
                        <span class="bused-fontstyle">{{
                          !!infodata.enterprise_info ? infodata.enterprise_info.company_former_name : '--'
                        }}</span>
                      </div>
                      <div
                        class="bothername-bused"
                        v-if="!!infodata.enterprise_info && !!infodata.enterprise_info.company_english_name"
                      >
                        <span class="bused-btle">英文名：</span>
                        <span class="bused-fontstyle">{{ infodata.enterprise_info.company_english_name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bhead-bcomscore">
                  <div class="bcomscore-scorebox">
                    <span class="bcomscore-bcomnum" v-if="infodata.score">{{
                      infodata.score['inno_comp'] | formatNum
                    }}</span>
                    <span class="bcomscore-bcomname">创新力指数</span>
                  </div>
                  <div class="bcomscore-scorebar">
                    <!-- 八个维度分的柱状图 -->
                    <EnterListBarEchartItems :echartDatas="infodata.score"></EnterListBarEchartItems>
                    <!-- <IndexListBarChart :echartDatas="[(94.34).toFixed(0)]" :barColor="'#B61F22'"></IndexListBarChart> -->
                  </div>
                </div>
              </div>
              <div class="bhead-brightopera">
                <div class="brightopera-hreport">
                  <p class="hreport-hreptext">创新评价报告：</p>
                  <span class="hreport-hrepitem">
                    <!-- <span class="hrepitem-repname">知识产权质量报告：</span> -->
                    <!-- hrepbtn-disabled v-show="!isBuy" -->
                    <!-- localOrganization 当前用户不是本地机构，则生成报告/监控推送不可点击 -->
                    <el-button
                      class="hrepitem-repbtn hrepbtn-disabled"
                      :disabled="true"
                      v-if="!infodata.localOrganization"
                      >生成报告</el-button
                    >
                    <el-button class="hrepitem-repbtn" v-else @click="toReportcompany('rep')">生成报告</el-button
                    ><!-- @click="generateReportHandleClick"-->
                    <!-- <div class="isbtnbox" v-show="isBuy">
                      <el-button class="lookieee-btn" @click="lookPdf">查看</el-button>
                      <el-button class="refresh-btn" @click="toReportcompany('rep')">刷新</el-button>
                    </div> -->
                  </span>
                  <!-- <span class="hreport-hrepitem">
                    <div>
                      <el-button
                        class="hrepitem-repbtn hrepbtn-disabled"
                        :disabled="true"
                        v-if="!infodata.localOrganization"
                        >订阅追踪（季报）</el-button
                      >
                      <el-button class="hrepitem-repbtn" @click="toReportcompany('sub')">订阅追踪（季报）</el-button>
                    </div>
                  </span> -->
                  <!-- <span class="hreport-hrepitem">
                    <span class="hrepitem-repname">监控推送：</span>
                    <div v-if="!infodata.localOrganization">
                      <el-button class="hrepitem-repbtn hrepbtn-disabled" :disabled="true">订阅季报</el-button>
                    </div>
                    <div v-else>
                      <el-button class="hrepitem-repbtn" @click="toReportcompany('sub')" v-show="!isSub">订阅季报</el-button>
                      <div class="isbtnbox" v-show="isSub">
                        <el-button class="lookieee-btn" @click="lookPdf">查看</el-button>
                        <el-button class="refresh-btn" @click="toReportcompany('sub')">续订</el-button>
                      </div>
                    </div>
                  </span> -->
                </div>
                <!-- <div class="brightopera-happlyimg brightopera-creditimg">
                  <img class="creditimg-image" src="@/assets/images/btorg_credit_china.png" />
                  <el-button class="creditimg-btnstyle creditimg-btncheck" v-if="infodata.name" @click="goCreditPage(infodata.name)">查看信用</el-button>
                  <el-button class="creditimg-btnstyle creditimg-btncheck" v-else>查看信用</el-button>
                  <el-button class="creditimg-btnstyle" @click="goRankPage">信用排行</el-button>
                  <span class="applyimg-name">手机查看本页</span>
                </div> -->
              </div>
            </div>
            <!-- 内容详情-折叠看板功能实现 -->
            <div :class="['borgmain-bcollapsebox', boardLoading ? '' : 'borgmain-hascon-block']">
              <el-collapse class="bcollapsebox-collapsecon" v-model="foldActiveNames" @change="foldHandleChange">
                <!-- 内容-基本信息 -->
                <el-collapse-item class="collapsecon-collitem" name="1">
                  <!-- title="基本信息" -->
                  <!-- <i class="iconfont icon-sanjiaodown collitem-tlearrows"></i> -->
                  <template slot="title">
                    基本信息
                    <i
                      :class="[
                        'iconfont',
                        'icon-sanjiaodown',
                        'collitem-tlearrows',
                        judgeActive('1') !== -1 ? '' : 'downArrow'
                      ]"
                    ></i>
                  </template>
                  <div class="bbasic-comcon bbasic-basiccon">
                    <div class="bbasic-basitem">
                      <div class="display-flex">
                        <div class="basitem-bitemnape">
                          <span class="weight-700">注册资本：</span>
                          <span class="">{{
                            !!infodata.enterprise_info ? infodata.enterprise_info.company_registered_capital : '--'
                          }}</span>
                        </div>
                        <div class="basitem-bitemnape">
                          <span class="weight-700">类型：</span>
                          <span class="">{{
                            !!infodata.enterprise_info ? infodata.enterprise_info.company_type : '--'
                          }}</span>
                        </div>
                        <div class="basitem-bitemnape">
                          <span>
                            <span class="weight-700">成立日期：</span>
                            <span class="" v-if="infodata.enterprise_info">{{
                              infodata.enterprise_info['company_registration_date'] | formatDate
                            }}</span>
                            <span class="" v-else>--</span>
                          </span>
                        </div>
                      </div>
                      <div class="basitem-bitemnape">
                        <span>
                          <span class="weight-700">法定代表人：</span>
                          <span class="">{{
                            !!infodata.enterprise_info ? infodata.enterprise_info.company_legal_person : '--'
                          }}</span>
                        </span>
                      </div>
                    </div>

                    <div class="bbasic-basitem">
                      <div class="display-flex">
                        <div class="basitem-bitemnape">
                          <span class="weight-700">统一社会信用代码：</span>
                          <span class="">{{
                            !!infodata.enterprise_info ? infodata.enterprise_info.company_credit_code : '--'
                          }}</span>
                        </div>
                        <div class="basitem-bitemnape">
                          <span class="weight-700">所属地区：</span>
                          <span class="">{{ !!infodata.dist ? infodata.dist : '--' }}</span>
                          <!-- <span class="propbs" v-if="infodata.baoshui"> 天津港保税区 </span> -->
                        </div>
                        <div class="basitem-bitemnape">
                          <span class="weight-700">官网：</span>
                          <span class="">
                            <span class="a-color-blue" @click="openWeb">
                              {{
                                !!infodata.enterprise_info && infodata.enterprise_info['company_web']
                                  ? infodata.enterprise_info['company_web']
                                  : '--'
                              }}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="display-flex">
                        <div class="basitem-bitemnape">
                          <span class="weight-700">纳税人识别号：</span>
                          <span class="">{{
                            !!infodata.enterprise_info ? infodata.enterprise_info.company_tax_identification : '--'
                          }}</span>
                        </div>
                        <div class="basitem-bitemnape">
                          <span class="weight-700">城市：</span>
                          <span class="">{{ !!infodata.city ? infodata.city : '--' }}</span>
                        </div>
                        <div class="basitem-bitemnape">
                          <span class="weight-700">电话：</span>
                          <span class="">{{
                            !!infodata.enterprise_info ? infodata.enterprise_info.company_telephone : '--'
                          }}</span>
                        </div>
                      </div>
                      <div class="display-flex">
                        <div class="basitem-bitemnape">
                          <span class="weight-700">组织机构代码：</span>
                          <span class="">{{
                            !!infodata.enterprise_info ? infodata.enterprise_info.company_organizing_code : ''
                          }}</span>
                        </div>
                        <div class="basitem-bitemnape">
                          <span class="weight-700">地址：</span>
                          <span class="">{{ !!infodata.addr ? infodata.addr : '--' }}</span>
                        </div>
                        <div class="basitem-bitemnape">
                          <span class="weight-700">邮箱：</span>
                          <span>{{ !!infodata.enterprise_info ? infodata.enterprise_info.company_email : '--' }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="bbasic-basitem">
                      <div class="basitem-bitemnape" style="display: flex">
                        <span class="weight-700 bitemnape-float" style="white-space: nowrap">简介：</span>
                        <span class="bitemnape-float bitemnape-infotext">{{
                          !!infodata.enterprise_info ? infodata.enterprise_info.company_abstract : '--'
                        }}</span>
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
                <!-- 内容-知识产权质量 -->
                <el-collapse-item class="collapsecon-collitem" name="2">
                  <template slot="title">
                    创新力指数
                    <i
                      style="left: 84px"
                      :class="[
                        'iconfont',
                        'icon-sanjiaodown',
                        'collitem-tlearrows',

                        judgeActive('2') !== -1 ? '' : 'downArrow'
                      ]"
                    ></i>
                  </template>
                  <div class="binnov-innovcon">
                    <div class="innovcon-inovdimen">
                      <span class="bcomscore-bcomname innovimen-innovname">创新力指数</span>
                      <span class="bcomscore-bcomnum innovimen-innovnum" v-if="infodata.score">{{
                        infodata.score['inno_comp'] | formatNum
                      }}</span>
                      <div class="novdimen-novradar">
                        <SelfOrgInnovRadarChart :echartDatas="innovEchartDatas"></SelfOrgInnovRadarChart>
                      </div>
                    </div>
                    <div class="innovcon-novscorebox">
                      <!-- 右侧-综合创新力-各分值-创新产出力、成果转化力 -->
                      <div class="novscorebox-rowbox">
                        <div class="novscore-scoreitem">
                          <div class="scoreitem-scorename weight-700">创新产出力</div>
                          <div class="scoreitem-scorebar">
                            <!-- <BoardProgressBarEchart :echartDatas="[5]" :barColor="'rgb(68, 175, 240)'"></BoardProgressBarEchart>-->
                            <el-progress
                              v-if="infodata.score"
                              :text-inside="true"
                              :stroke-width="26"
                              :percentage="infodata.score['inno_prod'] * 10"
                              :show-text="false"
                              :color="'#036EB7'"
                            >
                            </el-progress>
                          </div>
                          <div class="scoreitem-scoreexp">
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">创新成果数量：</span>
                              <span class="color-666">{{ infodata.pnum | formatNum }} 项</span>
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">创新人才数量：</span>
                              <span class="color-666"
                                >{{ infodata.team == 50000 ? '≥ ' : '' }}{{ infodata.team | formatNum }} 人</span
                              >
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">第一优势领域成果数量：</span>
                              <span class="color-666" v-if="infodata.score_index"
                                >{{ infodata.score_index['1ipc_count'] | formatNum }} 项</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="novscore-scoreitem">
                          <div class="scoreitem-scorename weight-700">成果转化力</div>
                          <div class="scoreitem-scorebar">
                            <!-- <BoardProgressBarEchart :echartDatas="[5]" :barColor="'rgb(68, 175, 240)'"></BoardProgressBarEchart>-->
                            <el-progress
                              :text-inside="true"
                              :stroke-width="26"
                              :percentage="!!infodata.score ? !!infodata.score.inno_tran * 10 : 0"
                              :show-text="false"
                              :color="'#036EB7'"
                            ></el-progress>
                          </div>
                          <div class="scoreitem-scoreexp">
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">成果转让：</span>
                              <span class="color-666" v-if="infodata.score_index"
                                >{{ infodata.score_index['cpa_count'] | formatNum }} 次</span
                              >
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">成果许可/质押：</span>
                              <span class="color-666" v-if="infodata.lcp_pcount"
                                >{{ infodata.lcp_pcount | formatNum }} 次</span
                              >
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">协作研发：</span>
                              <span class="color-666" v-if="infodata.score_index"
                                >{{ infodata.score_index.coa | formatNum }} 项</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 右侧-综合创新力-各分值-创新技术水平、市场覆盖力 -->
                      <div class="novscorebox-rowbox">
                        <div class="novscore-scoreitem">
                          <div class="scoreitem-scorename weight-700">创新技术水平</div>
                          <div class="scoreitem-scorebar">
                            <!-- <BoardProgressBarEchart :echartDatas="[5]" :barColor="'rgb(68, 175, 240)'"></BoardProgressBarEchart>-->
                            <el-progress
                              :text-inside="true"
                              :stroke-width="26"
                              :percentage="!!infodata.score ? infodata.score.inno_tech * 10 : 0"
                              :show-text="false"
                              :color="'#B61F22'"
                            ></el-progress>
                          </div>
                          <div class="scoreitem-scoreexp">
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">发明专利：</span>
                              <span class="color-666">{{ infodata.pt1 | formatNum }} 件</span>
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">领域内优质专利：</span>
                              <span class="color-666">{{ infodata.hq | formatNum }} 件</span>
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">专利平均维持：</span>
                              <span class="color-666">{{ infodata.pls | formatNum }} 年</span>
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">第一优势领域专利权利要求：</span>
                              <span class="color-666" v-if="infodata.score_index"
                                >平均{{ infodata.score_index['1ipc_clm'] | formatNum }} 项</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="novscore-scoreitem">
                          <div class="scoreitem-scorename weight-700">市场覆盖力</div>
                          <div class="scoreitem-scorebar">
                            <!-- <BoardProgressBarEchart :echartDatas="[5]" :barColor="'rgb(68, 175, 240)'"></BoardProgressBarEchart>-->
                            <el-progress
                              :text-inside="true"
                              :stroke-width="26"
                              :percentage="!!infodata.score ? infodata.score.inno_mark * 10 : 0"
                              :show-text="false"
                              :color="'#B61F22'"
                            ></el-progress>
                          </div>
                          <div class="scoreitem-scoreexp">
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">全球布局成果：</span>
                              <span class="color-666">{{ infodata.osp | formatNum }} 项</span>
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">覆盖地区：</span>
                              <span class="color-666" v-if="infodata.score_index"
                                >{{ infodata.score_index['glob_count'] | formatNum }} 个国家/地区</span
                              >
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">美日欧专利：</span>
                              <span class="color-666">{{ infodata.karea | formatNum }} 件</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 右侧-综合创新力-各分值-创新驱动力、行业影响力 -->
                      <div class="novscorebox-rowbox">
                        <div class="novscore-scoreitem">
                          <div class="scoreitem-scorename weight-700">创新驱动力</div>
                          <div class="scoreitem-scorebar">
                            <!-- <BoardProgressBarEchart :echartDatas="[5]" :barColor="'rgb(68, 175, 240)'"></BoardProgressBarEchart>-->
                            <el-progress
                              :text-inside="true"
                              :stroke-width="26"
                              :percentage="infodata.score ? infodata.score.inno_dynamic * 10 : 0"
                              :show-text="false"
                              :color="'#036EB7'"
                            ></el-progress>
                          </div>
                          <div class="scoreitem-scoreexp">
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">引用技术领域：</span>
                              <span class="color-666" v-if="infodata.score_index">
                                {{ infodata.score_index['cb_ic'] | formatNum }} 个
                              </span>
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">产学研专利：</span>
                              <span class="color-666"
                                >{{ (infodata.score_index ? infodata.score_index.iur : 0) | formatNum }} 件</span
                              >
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">引用现有技术：</span>
                              <span class="color-666"
                                >每项成果平均
                                {{ (infodata.score_index ? infodata.score_index.cbm : 0) | formatNum }} 次</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="novscore-scoreitem">
                          <div class="scoreitem-scorename weight-700">行业影响力</div>
                          <div class="scoreitem-scorebar">
                            <!-- <BoardProgressBarEchart :echartDatas="[5]" :barColor="'rgb(68, 175, 240)'"></BoardProgressBarEchart>-->
                            <el-progress
                              :text-inside="true"
                              :stroke-width="26"
                              :percentage="infodata.score ? infodata.score.inno_infl * 10 : 0"
                              :show-text="false"
                              :color="'#036EB7'"
                            ></el-progress>
                          </div>
                          <div class="scoreitem-scoreexp">
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">近十年成果被引：</span>
                              <span class="color-666">
                                {{ (infodata.score_index ? infodata.score_index['10y_cf'] : 0) | formatNum }}次
                              </span>
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">成果覆盖领域：</span>
                              <span class="color-666"
                                >{{ (infodata.score_index ? infodata.score_index.ipc : 0) | formatNum }} 个</span
                              >
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">成果影响领域：</span>
                              <span class="color-666">
                                {{ !!infodata.score_index ? infodata.score_index['cf_ic'] : '--' }} 个
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 右侧-综合创新力-各分值-成果保护力、创新活跃度 -->
                      <div class="novscorebox-rowbox">
                        <div class="novscore-scoreitem">
                          <div class="scoreitem-scorename weight-700">成果保护力</div>
                          <div class="scoreitem-scorebar">
                            <!-- <BoardProgressBarEchart :echartDatas="[5]" :barColor="'rgb(68, 175, 240)'"></BoardProgressBarEchart>-->
                            <el-progress
                              :text-inside="true"
                              :stroke-width="26"
                              :percentage="!!infodata.score ? infodata.score.inno_prot * 10 : 0"
                              :show-text="false"
                              :color="'#B61F22'"
                            >
                            </el-progress>
                          </div>
                          <div class="scoreitem-scoreexp">
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">有效专利：</span>
                              <span class="color-666">{{ infodata.ls2 | formatNum }} 件</span>
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">剩余保护期限：</span>
                              <span class="color-666">平均{{ infodata.prl | formatNum }} 年</span>
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">有效保护地区：</span>
                              <span class="color-666"
                                >{{
                                  (infodata.score_index ? infodata.score_index['fmc_ls2'] : 0) | formatNum
                                }}
                                个国家/地区</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="novscore-scoreitem">
                          <div class="scoreitem-scorename weight-700">创新活跃度</div>
                          <div class="scoreitem-scorebar">
                            <!-- <BoardProgressBarEchart :echartDatas="[5]" :barColor="'rgb(68, 175, 240)'"></BoardProgressBarEchart>-->
                            <el-progress
                              :text-inside="true"
                              :stroke-width="26"
                              :percentage="infodata.score ? infodata.score.inno_activity * 10 : 0"
                              :show-text="false"
                              :color="'#B61F22'"
                            ></el-progress>
                          </div>
                          <div class="scoreitem-scoreexp">
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">近三年复合增长率：</span>
                              <span class="color-666" v-if="infodata.score_index"
                                >{{ (infodata.score_index.cagr * 100) | formatNum }} %</span
                              >
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">近三年成果新增：</span>
                              <span class="color-666" v-if="infodata.score_index"
                                >{{ infodata.score_index['3y_pnum'] | formatNum }} 项</span
                              >
                            </div>
                            <div class="scoreexp-nitemnape block-left">
                              <span class="weight-700 color-666">近三年成果运营：</span>
                              <span class="color-666" v-if="infodata.score_index"
                                >{{ infodata.score_index['3y_cpa_count'] | formatNum }} 次</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
                <!-- 内容-技术信息 -->
                <el-collapse-item class="collapsecon-collitem" name="3">
                  <template slot="title">
                    技术信息
                    <i
                      :class="[
                        'iconfont',
                        'icon-sanjiaodown',
                        'collitem-tlearrows',
                        judgeActive('3') !== -1 ? '' : 'downArrow'
                      ]"
                    ></i>
                  </template>
                  <div class="bbasic-comcon binnov-techcon">
                    <div class="bbasic-basitem">
                      <div class="basitem-bitemnape basitem-bitemhstech">
                        <span class="weight-700">技术发源地：</span>
                        <img
                          v-if="!!infodata.orig"
                          class="orig-flagimg"
                          :src="require('@/assets/images/img2/' + infodata.orig.toUpperCase() + '.png')"
                        />
                        <span class="">{{ !!infodata.orig1 ? infodata.orig1 : '' }}</span>
                      </div>
                    </div>
                    <div class="bbasic-basitem techcon-techcon">
                      <div class="basitem-bitemnape techcon-techitem">
                        <span class="weight-700">所属行业：</span>
                        <span class="techitem-tag tagstyle-borderorange" v-if="infodata.mind">{{ infodata.mind }}</span>
                        <template v-for="(inditem, indindex) in infodata.ind">
                          <span class="techitem-tag tagstyle-borderblue" :key="indindex">{{ inditem }}</span>
                        </template>
                        <!-- <span class="techitem-tag tagstyle-bgblue">计算机、通信和其他电子设备制造业</span> -->
                      </div>
                      <div class="basitem-bitemnape techcon-techitem">
                        <span class="weight-700">所属技术领域：</span>
                        <span class="techitem-tag tagstyle-bgorange" v-if="infodata.mfield">{{
                          infodata.mfield.text
                        }}</span>
                        <span v-for="(fielditem, fieldindex) in infodata.field" :key="fieldindex">
                          <span class="techitem-tag tagstyle-bgblue" v-if="fielditem.text">{{ fielditem.text }}</span>
                        </span>
                        <!-- <span class="techitem-tag tagstyle-bgblue"
                      >液晶显示/光的控制</span
                    > -->
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
                <!-- 内容-创新人才 -->
                <el-collapse-item class="collapsecon-collitem" name="4">
                  <template slot="title">
                    创新人才
                    <i
                      :class="[
                        'iconfont',
                        'icon-sanjiaodown',
                        'collitem-tlearrows',
                        judgeActive('4') !== -1 ? '' : 'downArrow'
                      ]"
                    ></i>
                  </template>
                  <div class="bbasic-comcon binnov-talentscon">
                    <div class="bbasic-basitem">
                      <div class="basitem-bitemnape">
                        <span class="weight-700">创新人才数量：</span>
                        <span class="">{{ infodata.team >= 50000 ? '≥ ' : '' }}{{ infodata.team | formatNum }} 人</span>
                      </div>
                    </div>
                    <div class="talentscon-talcon" v-if="infodata.mexp && infodata.mexp.length > 0">
                      <div class="weight-700">主要创新人才：</div>
                      <div class="talcon-tallist">
                        <template v-for="(talentsItem, talentsIndex) in infodata.mexp">
                          <div class="tallist-talitem" :key="talentsIndex">
                            <div class="talitem-talimgbox">
                              <!-- 创新人才显示默认图片,有人名时显示第一个字姓 -->
                              <!-- <img class="talimgbox-imglogo" :src="!!talentsItem.logo ? talentsItem.logo : require('@/assets/images/default_tal_board.png')"/> -->
                              <div class="talimgbox-tallogo" v-if="talentsItem.name">
                                {{ talentsItem.name.slice(0, 1) }}
                              </div>
                              <img class="talimgbox-imglogo" v-else src="@/assets/images/default_tal_white.png" />
                            </div>
                            <div class="talitem-talinfo">
                              <span class="talinfo-name">{{ talentsItem.name }}</span>
                              <span class="talinfo-tinfo">
                                <span class="tinfo-socre">{{ talentsItem.inno_comp }}</span>
                                <span class="tinfo-mind" v-if="talentsItem.mfield">{{ talentsItem.mfield }}</span>
                              </span>
                            </div>
                          </div>
                        </template>
                        <!-- <div class="tallist-talitem">
                      <div class="talitem-talimgbox">
                        <img
                          class="talimgbox-imglogo"
                          src="@/assets/images/default_tal.png"
                        />
                      </div>
                      <div class="talitem-talinfo">
                        <span class="talinfo-name">李东珂</span>
                        <span class="talinfo-tinfo">
                          <span class="tinfo-socre">94.34</span>
                          <span class="tinfo-mind">半导体器件</span>
                        </span>
                      </div>
                    </div> -->
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
                <!-- 内容-竞争与合作 -->
                <el-collapse-item class="collapsecon-collitem" name="5">
                  <template slot="title">
                    竞争与合作
                    <i
                      :class="[
                        'iconfont',
                        'icon-sanjiaodown',
                        'collitem-tlearrows',
                        'collitem-iconleft-84',
                        judgeActive('5') !== -1 ? '' : 'downArrow'
                      ]"
                    ></i>
                  </template>
                  <div class="bbasic-comcon binnov-competitecon">
                    <div class="competitecon-copmgraphbox">
                      <SelfCopmSankeyChart
                        :echartDatas="copmEchartDatas"
                        :companyName="infodata.name"
                        v-if="copmEchartDatas.datas && copmEchartDatas.links"
                      ></SelfCopmSankeyChart>
                    </div>
                  </div>
                </el-collapse-item>
                <!-- 内容-主要代理机构 -->
                <el-collapse-item class="collapsecon-collitem" name="6">
                  <template slot="title">
                    主要代理机构
                    <i
                      :class="[
                        'iconfont',
                        'icon-sanjiaodown',
                        'collitem-tlearrows',
                        'collitem-iconleft-97',
                        judgeActive('6') !== -1 ? '' : 'downArrow'
                      ]"
                    ></i>
                  </template>
                  <div class="bbasic-comcon binnov-agencycon">
                    <div class="talcon-tallist agencyconcon-agylist">
                      <template v-for="(agencyItem, agencyIndex) in infodata.agency">
                        <div class="tallist-talitem agylist-agyitem" :key="agencyIndex">
                          <div class="talitem-talimgbox agyitem-agyimgbox">
                            <img class="agyimgbox-imglogo" v-if="!!agencyItem.logo" :src="agencyItem.logo" />
                            <div class="agyimgbox-agylogo" v-else-if="agencyItem.name">
                              {{ agencyItem.name.slice(0, 4) }}
                            </div>
                            <img class="agyimgbox-imglogo" :src="agencyDefaultLogo" v-else />
                          </div>
                          <div class="talitem-talinfo">
                            <span class="talinfo-name">{{ agencyItem.name }}</span>
                            <span class="talinfo-tinfo">
                              <span class="tinfo-socre">{{ agencyItem.score }}</span>
                              <span class="tinfo-mind">{{ agencyItem.mfield }}</span>
                            </span>
                          </div>
                        </div>
                      </template>
                      <!-- <div class="tallist-talitem agylist-agyitem">
                    <div class="talitem-talimgbox agyitem-agyimgbox">
                      <img
                        class="talimgbox-imglogo"
                        src="@/assets/images/org_img1.png"
                      />
                    </div>
                    <div class="talitem-talinfo">
                      <span class="talinfo-name"
                        >北京同达恒信知识产权代理有限公司</span
                      >
                      <span class="talinfo-tinfo">
                        <span class="tinfo-socre">94.34</span>
                        <span class="tinfo-mind">光学/摄像/显示装置</span>
                      </span>
                    </div>
                  </div> -->
                    </div>
                  </div>
                </el-collapse-item>
                <!-- 内容-专利申请 -->
                <el-collapse-item class="collapsecon-collitem" name="7">
                  <template slot="title">
                    专利申请
                    <i
                      :class="[
                        'iconfont',
                        'icon-sanjiaodown',
                        'collitem-tlearrows',
                        'collitem-iconleft-70',
                        judgeActive('7') !== -1 ? '' : 'downArrow'
                      ]"
                    ></i>
                  </template>
                  <div class="bbasic-comcon binnov-aplicatecon">
                    <div class="bbasic-basitem">
                      <div class="basitem-bitemnape">
                        <span class="weight-700">已公开专利申请：</span>
                        <span>{{ infodata.pnum | formatNum }}</span>
                        件
                      </div>
                    </div>
                    <div class="bbasic-basitem appcon-appgraph">
                      <span class="appgraph-appname weight-700">专利类型分布：</span>
                      <div class="appgraph-agraphbox appgraph-agraphbox-h36">
                        <!-- <div class="agraphbox-echartbox"></div> -->
                        <SelfAppBarChart
                          :width="'904px'"
                          :barColors="['#41C7DB', '#0070C0', '#5B9BD5']"
                          :echartDatas="appEchartDatas"
                          :innovatorId="infodata.id"
                        ></SelfAppBarChart>
                      </div>
                    </div>
                    <div class="bbasic-basitem appcon-appgraph">
                      <span class="appgraph-appname weight-700">专利法律效力：</span>
                      <div class="appgraph-agraphbox appgraph-agraphbox-h36">
                        <!-- <div class="agraphbox-echartbox"></div> -->
                        <SelfAppBarChart
                          :width="'904px'"
                          :barColors="['#1973ca', '#66B661', '#ccc']"
                          :echartDatas="lawEchartDatas"
                          :innovatorId="infodata.id"
                        ></SelfAppBarChart>
                      </div>
                    </div>
                    <div class="bbasic-basitem">
                      <div class="basitem-bitemnape">
                        <span class="weight-700">最早专利公开：</span>
                        <span v-if="infodata.epd">{{ infodata.epd | formatDateNoZero }}</span>
                        <span v-else>--</span>
                      </div>
                    </div>
                    <div class="bbasic-basitem appcon-appgraph appcon-atrend-mt30">
                      <span class="appgraph-appname weight-700">专利公开趋势：</span>
                      <div class="appgraph-agraphbox appgraph-agraphbox-h250">
                        <!-- <div class="agraphbox-echartbox"></div> -->
                        <SelfOpenTrendBarChart
                          :barColors="'#ee7524'"
                          :echartDatas="trendEchartDatas"
                          :innovatorId="infodata.id"
                        ></SelfOpenTrendBarChart>
                      </div>
                    </div>
                    <div class="bbasic-basitem appcon-appgraph appcon-agloballayout-mt30">
                      <span class="appgraph-appname weight-700">专利全球布局：</span>
                      <div class="appgraph-agraphbox aglolayout-aglobal">
                        <div class="aglobal-aleftpart">
                          <span class="aglobal-countrynum">
                            <!-- {{!!infodata.osp ? infodata.osp : "--"}} -->
                            {{ !!infodata.globs && infodata.globs.length > 0 ? infodata.globs.length : '--' }}
                            <!-- {{!!infodata.score_index['glob_count'] ? infodata.score_index['glob_count'] : '--'}} -->
                            个国家/地区/国际组织</span
                          >
                          <div class="aglobal-mapbox">
                            <SelfGlobalWorldMapChart :echartDatas="mapEchartDatas"></SelfGlobalWorldMapChart>
                          </div>
                        </div>
                        <div class="aglobal-arightpart">
                          <span class="arightpart-tle">TOP5：</span>
                          <div class="arightpart-globaltop5">
                            <template v-for="(flagItem, flagIndex) in infodata.globs">
                              <div class="globaltop5-glolist" :key="flagIndex" v-if="flagIndex <= 4 && !!flagItem.code">
                                <img
                                  class="glolist-flagimg"
                                  :src="require('@/assets/images/img2/' + flagItem.code.toUpperCase() + '.png')"
                                />
                                <span class="glolist-flagname">{{ flagItem.name }}</span>
                              </div>
                            </template>
                            <!-- <div class="globaltop5-glolist">
                          <img
                            class="glolist-flagimg"
                            src="@/assets/images/org_global_flag2.png"
                          />
                          <span class="glolist-flagname">美国</span>
                        </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bbasic-basitem appcon-appgraph appcon-aotherpatent-mt30">
                      <span class="appgraph-appname weight-700">其他专利指标：</span>
                      <div class="appgraph-agraphbox aotherpat-apatindex">
                        <!-- 仪表盘-图 -->
                        <div class="apatindex-guagebox">
                          <div class="guagebox-patguage">
                            <div class="patguage-guagegraph">
                              <SelfPatIndexPercentGuageChart
                                :echartDatas="paaEchartDatas"
                                :guageMin="'0'"
                                :guageMax="'100'"
                              ></SelfPatIndexPercentGuageChart>
                            </div>
                          </div>
                          <div class="guagebox-patguage">
                            <div class="patguage-guagegraph">
                              <SelfPatIndexFaminChart
                                :echartDatas="invAuthEchartDatas"
                                :guageMin="'0'"
                                :guageMax="'1'"
                              ></SelfPatIndexFaminChart>
                            </div>
                          </div>
                          <div class="guagebox-patguage">
                            <div class="patguage-guagegraph">
                              <SelfPatIndexGuageChart
                                :echartDatas="yearEchartDatas"
                                :guageMin="'0'"
                                :guageMax="'10'"
                              ></SelfPatIndexGuageChart>
                            </div>
                          </div>
                          <div class="guagebox-patguage">
                            <div class="patguage-guagegraph">
                              <SelfPatIndexGuageChart
                                :echartDatas="lifeEchartDatas"
                                :guageMin="'0'"
                                :guageMax="'20'"
                              ></SelfPatIndexGuageChart>
                            </div>
                          </div>
                        </div>
                        <!-- 各类型专利数量 -->
                        <div class="apatindex-patnum">
                          <div :class="['patnum-patitem', infodata.hq == 0 ? 'patnum-patitem-active' : '']">
                            <span class="patitem-disblock">领域内优质专利</span>
                            <span class="patitem-disblock patitem-num">{{ infodata.hq | formatNum }}</span>
                          </div>
                          <div :class="['patnum-patitem', infodata.stand == 0 ? 'patnum-patitem-active' : '']">
                            <span class="patitem-disblock">技术标准专利</span>
                            <span class="patitem-disblock patitem-num">{{ infodata.stand | formatNum }}</span>
                          </div>
                          <div :class="['patnum-patitem', infodata.rewa == 0 ? 'patnum-patitem-active' : '']">
                            <span class="patitem-disblock">获奖专利</span>
                            <span class="patitem-disblock patitem-num">{{ infodata.rewa | formatNum }}</span>
                          </div>
                          <div :class="['patnum-patitem', infodata.cust == 0 ? 'patnum-patitem-active' : '']">
                            <span class="patitem-disblock">海关备案专利</span>
                            <span class="patitem-disblock patitem-num">{{ infodata.cust | formatNum }}</span>
                          </div>
                          <div :class="['patnum-patitem', infodata.liti == 0 ? 'patnum-patitem-active' : '']">
                            <span class="patitem-disblock">涉诉专利</span>
                            <span class="patitem-disblock patitem-num">{{ infodata.liti | formatNum }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
                <!-- 内容-商标申请 -->
                <el-collapse-item class="collapsecon-collitem" name="8">
                  <template slot="title">
                    商标申请
                    <i
                      :class="[
                        'iconfont',
                        'icon-sanjiaodown',
                        'collitem-tlearrows',
                        'collitem-iconleft-70',
                        judgeActive('8') !== -1 ? '' : 'downArrow'
                      ]"
                    ></i>
                  </template>
                  <div class="bbasic-comcon binnov-trademarkcon">
                    <div class="bbasic-basitem">
                      <div class="basitem-bitemnape">
                        <span class="weight-700">已公开商标申请：</span>
                        <span>{{ infodata.trnum | formatNum }} </span>
                        项
                      </div>
                    </div>
                    <div class="bbasic-basitem tradecon-tradeclassify">
                      <span class="tradeclassify-tradename weight-700">主要商标分类：</span>
                      <template v-for="(trcitem, trcindex) in infodata.trc">
                        <span
                          :key="trcindex"
                          :class="['techitem-tag', trcindex == 0 ? 'tagstyle-bgorange' : 'tagstyle-bgblue']"
                          >{{ trcitem }}</span
                        >
                      </template>
                      <!-- <span class="techitem-tag tagstyle-bgorange"
                    >计算机、通信和其他电子设备制造业</span
                  > -->
                    </div>
                    <div class="bbasic-basitem appcon-appgraph appcon-tradelawgraph">
                      <span class="appgraph-appname weight-700">商标法律效力：</span>
                      <div class="appgraph-agraphbox appgraph-agraphbox-h36">
                        <SelfAppBarChart
                          :width="'904px'"
                          :barColors="['#41C7DB', '#0070C0', '#ccc']"
                          :echartDatas="brandEchartDatas"
                          :innovatorId="infodata.id"
                        ></SelfAppBarChart>
                      </div>
                    </div>
                    <div class="bbasic-basitem appcon-appgraph appcon-aotherpatent-mt30">
                      <span class="appgraph-appname weight-700">其他商标指标：</span>
                      <div class="appgraph-agraphbox aotherpat-apatindex">
                        <!-- 仪表盘-图 -->
                        <div class="apatindex-guagebox">
                          <div class="guagebox-patguage">
                            <div class="patguage-guagegraph">
                              <SelfPatIndexPercentGuageChart
                                :echartDatas="paaTradeEchartDatas"
                                :guageMin="'0'"
                                :guageMax="'100'"
                              ></SelfPatIndexPercentGuageChart>
                            </div>
                          </div>
                          <div class="guagebox-patguage">
                            <div class="patguage-guagegraph">
                              <SelfPatIndexFaminChart
                                :echartDatas="regTradeEchartDatas"
                                :guageMin="'0'"
                                :guageMax="'1'"
                              ></SelfPatIndexFaminChart>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 页尾 -->
    <!-- <CommonFooterItems></CommonFooterItems> -->
  </div>
</template>

<script>
import enterpriseDetail from './enterpriseDetail.js'
export default {
  ...enterpriseDetail
}
</script>

<style  scoped>
@import './enterpriseDetail.css';
.platform-auto-complete :deep(.el-autocomplete-suggestion__wrap) {
  padding: 5px 0;
}
.platform-auto-complete :deep(.el-autocomplete-suggestion__wrap ul li) {
  pointer-events: none; /* 阻止可点击事件 */
}
.platform-auto-complete :deep(.el-autocomplete-suggestion__wrap ul li .default) {
  text-align: center;
  color: #999;
}
/* 这个分号一定要写，要不会报错 */
</style>
