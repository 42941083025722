<template>
  <!-- 首页-列表榜单-柱状图 -->
  <div ref="myEcharts" :style="{ margin: '0 auto', width: '100%', height: '100%' }"></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    echartDatas: {
      type: Array,
      default() {
        return []
      }
    },
    barColors: {
      type: String,
      default() {
        return ''
      }
    },
    innovatorId: {
      // 创新主体id
      type: String,
      default() {
        return ''
      }
    }
  },
  components: {},
  created() {},
  mounted() {
    this.drawBar()
  },
  methods: {
    drawBar() {
      // this.$refs.echartBar.removeAttribute("_echarts_instance_");
      // document.getElementById('echartLine').removeAttribute("_echarts_instance_");
      // 基于准备好的dom，初始化echarts实例
      let myEcharts = this.$echarts.init(this.$refs.myEcharts, 'light')
      let xAxisDatas = []
      let seriesDatas = []
      let gridRight
      gridRight = 100 - Math.ceil((100 / 30) * this.echartDatas.length) + '%'
      for (let i = this.echartDatas.length - 1; i >= 0; i--) {
        xAxisDatas.push(this.echartDatas[i].name)
        seriesDatas.push(this.echartDatas[i].value)
      }
      console.log(this.echartDatas, '柱状图this.echartDatas')
      console.log(xAxisDatas, 'xAxisDatas')
      let maxVal = seriesDatas[0] // 获取最大值
      for (let i in seriesDatas) {
        if (seriesDatas[i] > maxVal) {
          maxVal = seriesDatas[i]
        }
      }
      maxVal = Math.ceil(maxVal / 100) * 100
      let barColor = this.barColors
      // 指定图表的配置项和数据
      let option = {
        grid: {
          top: '10%',
          left: '0%',
          right: gridRight,
          bottom: '0%',
          containLabel: true
        },
        yAxis: {
          type: 'value',
          max: maxVal,
          axisLabel: {
            color: '#707070'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#707070'
          },
          data: xAxisDatas
        },
        // 处理千分位

        series: [
          {
            name: 'Direct',
            type: 'bar',
            stack: 'total',
            barWidth: 20,
            label: {
              show: true,
              position: 'top',
              color: '#707070',
              fontFamily: 'DIN-Pro-Regular-2',
              formatter: function (params) {
                return params.value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
              }
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: barColor
            },
            data: seriesDatas
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      myEcharts.setOption(option)
    }
  },
  watch: {
    echartDatas: {
      handler() {
        // val, oldVal
        // console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
        this.drawBar()
      }
    }
  }
}
</script>

<style>
</style>
