import './assets/style/reset.css'
import './assets/style/common.css'
import './assets/style/iconfont/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 引入font.css
import './assets/style/font.css'
import './assets/style/textfont/textfont.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Router from 'vue-router'
import ElementUI from 'element-ui'
import Axios from 'axios'
import qs from 'qs'
import * as echarts from 'echarts'
// import { quillEditor } from 'vue-quill-editor'
import VueQuillEditor from 'vue-quill-editor'
import waterfall from "vue-waterfall2"
import pinyin from 'js-pinyin'
const { urlUser, urlCommon, baseURL } = require('./config/env.' + process.env.NODE_ENV)

Axios.defaults.baseURL = baseURL
Vue.config.productionTip = false
Vue.prototype.$axios = Axios
Vue.prototype.$qs = qs
Vue.prototype.$echarts = echarts
Vue.prototype.$pinyin = pinyin


Vue.prototype.urlCommon = urlCommon
Vue.prototype.urlUser = urlUser

Vue.prototype.logoutHref = '/logout'

Vue.use(waterfall)

// axios响应拦截器
// Axios.interceptors.response.use(
//   response => {
//     // 拦截响应，做统一处理
//     if (response.data.code == '70001') {
//       window.location.href = '/errorpage'
//     }
//     return response
//   },
//   error => {
//     // 另外的错误
//     console.log(error.response)
//     return Promise.reject(error.response.status)
//   }
// )

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(ElementUI)
// 富文本编辑器
Vue.use(VueQuillEditor)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
