<template>
  <!-- 企业看板-其他专利指标-仪表盘-显示数字 -->
  <div ref="myEcharts" :style="{ width: '100%', height: '100%' }"></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    echartDatas: {
      type: Array,
      default() {
        return []
      }
    },
    guageMin: {
      type: String,
      default() {
        return ''
      }
    },
    guageMax: {
      type: String,
      default() {
        return ''
      }
    }
  },
  components: {},
  created() {},
  mounted() {
    this.drawBar()
  },
  methods: {
    drawBar() {
      // this.$refs.echartBar.removeAttribute("_echarts_instance_");
      // document.getElementById('echartLine').removeAttribute("_echarts_instance_");
      // 基于准备好的dom，初始化echarts实例
      let myEcharts = this.$echarts.init(this.$refs.myEcharts, 'light')
      let seriesDatas = this.echartDatas
      let minVal = this.guageMin
      let maxVal = this.guageMax
      // 指定图表的配置项和数据
      let option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: minVal,
            max: maxVal,
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                width: 8,
                color: [
                  [0.25, '#FF6E76'],
                  [0.5, '#FDDD60'],
                  [0.75, '#58D9F9'],
                  [1, '#7CFFB2']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '15%',
              width: 8,
              offsetCenter: [0, '-50%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              length: 3,
              splitNumber: 5,
              distance: 5,
              lineStyle: {
                color: 'auto',
                width: 1
              }
            },
            splitLine: {
              length: 10,
              distance: 5,
              lineStyle: {
                color: 'auto',
                width: 1.5
              }
            },
            radius: '80%',
            axisLabel: {
              color: '#666666',
              fontSize: 8,
              fontFamily: "'DIN-Pro-Regular-2','SourceHanSansCN-Normal'",
              distance: -30
            },
            title: {
              offsetCenter: [0, '-20%'],
              fontSize: 12,
              fontFamily: "'DIN-Pro-Regular-2','SourceHanSansCN-Normal'",
              color: '#000000'
            },
            detail: {
              fontSize: 14,
              fontFamily: "'DIN-Pro-Regular-2','SourceHanSansCN-Normal'",
              offsetCenter: [0, '0%'],
              valueAnimation: true,
              formatter: function (value) {
                return value.toFixed(1) + '年'
              },
              color: 'auto'
            },
            data: seriesDatas
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      myEcharts.setOption(option)
      // 柱状图-点击事件
      myEcharts.on('click', (params) => {
        // 打印当前点击柱状的数据
        console.log(params)
        // window.location =
      })
      // myEcharts.on('click', 'series.bar.label', function (params) {
      //   console.log(params)
      // })
    }
  },
  watch: {
    echartDatas: {
      handler() {
        // val, oldVal
        // console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
        this.drawBar()
      },
      deep: true
    }
  }
}
</script>

<style>
</style>
