<template>
  <!-- 首页-列表榜单-柱状图 -->
  <div ref="myEcharts" :style="{ margin: '0 auto', width: '100%', height: '100%' }"></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    echartDatas: {
      type: Object,
      default() {
        return {}
      }
    },
    companyName: {
      type: String,
      default() {
        return ''
      }
    }
  },
  components: {},
  created() {},
  mounted() {
    this.drawBar()
  },
  methods: {
    drawBar() {
      let _this = this
      console.log(_this.echartDatas, 'echartDatas')
      // if (_this.echartDatas) {
      let tempDatas = _this.echartDatas.datas
      let linksDatas = _this.echartDatas.links
      let seriesDatas = []
      for (let i = 0; i < tempDatas.length; i++) {
        if (tempDatas[i].depth == 0) {
          if (tempDatas[i].name == this.companyName) {
            seriesDatas.push({
              name: tempDatas[i].name,
              // depth: tempDatas[i].depth,
              // label: {
              //   position: 'left'
              // }
              itemStyle: {
                color: '#B61F22'
              }
            })
          } else {
            seriesDatas.push({
              name: tempDatas[i].name
              // depth: tempDatas[i].depth,
              // label: {
              //   position: 'left'
              // }
            })
          }
        } else {
          if (tempDatas[i].name == this.companyName) {
            seriesDatas.push({
              name: tempDatas[i].name,
              // depth: tempDatas[i].depth,
              // label: {
              //   position: 'left'
              // }
              itemStyle: {
                color: '#B61F22'
              }
            })
          } else {
            seriesDatas.push({
              name: tempDatas[i].name
              // depth: tempDatas[i].depth,
              // label: {
              //   position: 'left'
              // }
            })
          }
        }
      }
      // this.$refs.echartBar.removeAttribute("_echarts_instance_");
      // document.getElementById('echartLine').removeAttribute("_echarts_instance_");
      // 基于准备好的dom，初始化echarts实例
      let myEcharts = this.$echarts.init(this.$refs.myEcharts, 'light')
      let barDatas = this.echartDatas
      let barColor = 'rgb(229, 229, 239)'
      if (this.barColor) {
        barColor = this.barColor
      }
      console.log(seriesDatas, 'seriesDatas')
      // 指定图表的配置项和数据
      let option = {
        backgroundColor: '#FFFFFF',
        series: {
          type: 'sankey',
          layout: 'none',
          emphasis: {
            focus: 'adjacency'
          },
          data: seriesDatas,
          links: linksDatas,
          layoutIterations: 0,
          top: '5%',
          bottom: '5%',
          left: '0',
          right: '15%',
          lineStyle: {
            color: 'source',
            curveness: 0.5
          },
          itemStyle: {
            // color: '#1f77b4',
            // borderColor: '#1f77b4'
          },
          label: {
            color: '#333333',
            fontFamily: 'SourceHanSansCN-Normal',
            fontSize: 12
          }
        }
      }
      // 使用刚指定的配置项和数据显示图表。
      myEcharts.setOption(option)
    }
    // }
  },
  watch: {
    echartDatas: {
      handler() {
        // val, oldVal
        // console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
        this.drawBar()
      },
      deep: true
    }
  }
}
</script>

<style>
</style>
