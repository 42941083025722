<template>
  <!-- 资质自测-柱状图 -->
  <div ref="myEcharts" :style="{ width: width, height: '100%' }"></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    width: {
      type: String,
      default() {
        return ''
      }
    },
    echartDatas: {
      type: Array,
      default() {
        return []
      }
    },
    barColors: {
      type: Array,
      default() {
        return []
      }
    },
    innovatorId: {
      // 创新主体id
      type: String,
      default() {
        return ''
      }
    }
  },
  components: {},
  created() {},
  mounted() {
    this.drawBar()
  },
  methods: {
    formatNum(val) {
      // 三位数字之间加分隔符
      if (!val) return '0'
      // Number.parseFloat(val).toFixed(2)
      // val.toFixed(2).toString()
      val = val.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      return val
    },
    drawBar() {
      // this.$refs.echartBar.removeAttribute("_echarts_instance_");
      // document.getElementById('echartLine').removeAttribute("_echarts_instance_");
      // 基于准备好的dom，初始化echarts实例
      let myEcharts = this.$echarts.init(this.$refs.myEcharts, 'light')
      let seriesDatas = this.echartDatas
      let seriesDatas1 = []
      let seriesDatas2 = []
      let seriesDatas3 = []
      let seriesName1, seriesName2, seriesName3
      let maxVal = seriesDatas[0].value // 获取最大值
      for (let i = 0; i < seriesDatas.length; i++) {
        if (seriesDatas[i].value > maxVal) {
          maxVal = seriesDatas[i].value
        }
      }
      for (let i = 0; i < seriesDatas.length; i++) {
        if (seriesDatas[i].name == '发明' || seriesDatas[i].name == '未授权' || seriesDatas[i].name == '申请中') {
          seriesName1 = seriesDatas[i].name
          if (seriesDatas[i].value < Math.ceil(maxVal * 0.1)) {
            seriesDatas1.push({
              name: seriesDatas[i].name,
              value: Math.ceil(maxVal * 0.1),
              value1: seriesDatas[i].value // 这个是真实数据
            })
          } else {
            seriesDatas1.push({
              name: seriesDatas[i].name,
              value: seriesDatas[i].value,
              value1: seriesDatas[i].value // 这个是真实数据
            })
          }
        }
        if (seriesDatas[i].name == '实用新型' || seriesDatas[i].name == '有效' || seriesDatas[i].name == '已注册') {
          seriesName2 = seriesDatas[i].name
          if (seriesDatas[i].value < Math.ceil(maxVal * 0.1)) {
            seriesDatas2.push({
              name: seriesDatas[i].name,
              value: Math.ceil(maxVal * 0.1),
              value1: seriesDatas[i].value // 这个是真实数据
            })
          } else {
            seriesDatas2.push({
              name: seriesDatas[i].name,
              value: seriesDatas[i].value,
              value1: seriesDatas[i].value // 这个是真实数据
            })
          }
        }
        if (seriesDatas[i].name == '外观设计' || seriesDatas[i].name == '已失效' || seriesDatas[i].name == '无效') {
          seriesName3 = seriesDatas[i].name
          if (seriesDatas[i].value < Math.ceil(maxVal * 0.1)) {
            seriesDatas3.push({
              name: seriesDatas[i].name,
              value: Math.ceil(maxVal * 0.1),
              value1: seriesDatas[i].value // 这个是真实数据
            })
          } else {
            seriesDatas3.push({
              name: seriesDatas[i].name,
              value: seriesDatas[i].value,
              value1: seriesDatas[i].value // 这个是真实数据
            })
          }
        }
      }
      let barColors = this.barColors
      // 指定图表的配置项和数据
      let option = {
        tooltip: {
          show: false,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: false
        },
        grid: {
          top: '0%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        yAxis: {
          type: 'category',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          data: ['专利类型分布']
        },
        xAxis: {
          type: 'value',
          max: seriesDatas1[0].value + seriesDatas2[0].value + seriesDatas3[0].value,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: seriesName1, // '发明',
            type: 'bar',
            stack: 'total',
            barWidth: 40,
            emphasis: {
              disabled: true
            },
            label: {
              show: true,
              position: [1, '1%'],
              // position: 'insideLeft',
              // formatter: '{a}:\n{c}',
              formatter: function (params) {
                return params.data.name + '\n' + params.data.value1.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
              },
              color: '#fff',
              lineHeight: 18,
              fontSize: 12,
              clickable: true
            },
            itemStyle: {
              color: barColors[0]
            },
            data: seriesDatas1
          },
          {
            name: seriesName2, // '实用新型',
            type: 'bar',
            stack: 'total',
            barWidth: 40,
            emphasis: {
              disabled: true
              // focus: 'series'
            },
            label: {
              show: true,
              position: [1, '1%'],
              // position: 'insideLeft',
              // formatter: '{a}:\n{c}',
              formatter: function (params) {
                return params.data.name + '\n' + params.data.value1.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
              },
              color: '#fff',
              lineHeight: 18,
              clickable: true
            },
            itemStyle: {
              color: barColors[1]
            },
            data: seriesDatas2
          },
          {
            name: seriesName3, // '外观设计',
            type: 'bar',
            stack: 'total',
            barWidth: 40,

            emphasis: {
              disabled: true
              // focus: 'series'
            },
            label: {
              show: true,
              position: [1, '1%'],
              // position: 'insideLeft',
              // formatter: '{a}:\n{c}',
              formatter: function (params) {
                return params.data.name + '\n' + params.data.value1.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
              },
              color: '#fff',
              fontFamily: "'DIN-Pro-Regular-2','SourceHanSansCN-Normal'",
              lineHeight: 18
            },
            itemStyle: {
              color: barColors[2]
            },
            data: seriesDatas3
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      myEcharts.setOption(option)

      // myEcharts.on('click', 'series.bar.label', function (params) {
      //   console.log(params)
      // })
    }
  },
  watch: {
    echartDatas: {
      handler() {
        // val, oldVal
        // console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
        this.drawBar()
      },
      deep: true
    }
  }
}
</script>

<style>
</style>
