<template>
  <!-- 数据看板-企业看板-雷达图 -->
  <div ref="myEchart" :style="{ margin: '0 auto', width: '100%', height: '100%' }"></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    echartDatas: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {},
  created() {},
  mounted() {
    this.drawBar()
  },
  methods: {
    drawBar() {
      // this.$refs.echartBar.removeAttribute("_echarts_instance_");
      // document.getElementById('echartLine').removeAttribute("_echarts_instance_");
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(this.$refs.myEchart, 'light')
      let echartDatas = this.echartDatas
      // 指定图表的配置项和数据
      let option = {
        // title: {
        //   top: 0,
        //   text: '综合创新力',
        //   x: 'center',
        //   textStyle: {
        //     fontSize: 22,
        //     fontWeight: '700',
        //     color: '#333333'
        //   },
        //   subtext: '综合指数:"+comp_score+"',
        //   subtextStyle: {
        //     fontSize: 14,
        //     fontWeight: 'normal',
        //     color: '#ef8640'
        //   },
        //   itemGap: 5
        // },
        xAxis: {
          show: false
        },
        yAxis: {
          show: false
        },
        radar: {
          center: ['50%', '50%'],
          radius: '96%',
          axisLabel: {
            normal: {
              show: false
            }
          },
          name: {
            textStyle: {
              color: 'transparent'
            }
          },
          indicator: [
            {
              name: '创新产出力',
              max: 100
            },
            {
              name: '创新技术水平',
              max: 100
            },
            {
              name: '创新驱动力',
              max: 100
            },
            {
              name: '成果保护力',
              max: 100
            },
            {
              name: '成果转化力',
              max: 100
            },
            {
              name: '市场覆盖力',
              max: 100
            },
            {
              name: '行业影响力',
              max: 100
            },
            {
              name: '创新活跃度',
              max: 100
            }
          ]
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                value: echartDatas
              }
            ],
            itemStyle: {
              normal: {
                color: '#036EB7'
              }
            },
            label: {
              normal: {
                show: false,
                textStyle: {
                  color: '#81b75b',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 20
                }
              }
            }
          }
        ],
        reportShow: false
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    }
  },
  watch: {
    echartDatas: {
      handler() {
        // val, oldVal
        // console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
        this.drawBar()
      }
    }
  }
}
</script>

<style>
</style>
