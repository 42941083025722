<template>
  <!-- 首页-列表榜单-柱状图 -->
	<div ref="myEcharts" :style="{margin: '0 auto',width: '100%', height: '100%'}"></div>
</template>

<script>
  require('echarts/map/js/world.js')
  export default {
		data() {
			return {

			}
		},
		props: {
			echartDatas: {
				type: Array,
				default() {
					return []
				}
			},
			barColors: {
				type: String,
				default() {
					return ''
				}
			}
		},
		components: {
		},
		created() {
		},
		mounted() {
			this.drawBar()
		},
		methods: {
			drawBar() {
        let dataList = [ // 数据
          { name: '中国大陆', value: 1254685 }, { name: '德国', value: 83017 },
          { name: '俄罗斯', value: 5550 }, { name: '西班牙', value: 46182 },
          { name: '芬兰', value: 5367 }, { name: '法国', value: 6323 },
          { name: '英国', value: 6206 }, { name: '希腊', value: 1110 },
          { name: '澳大利亚', value: 6206 }, { name: '加拿大', value: 1110 }
        ]
        let nameMap = {
          Afghanistan: '阿富汗',
          Singapore: '新加坡',
          Angola: '安哥拉',
          Albania: '阿尔巴尼亚',
          'United Arab Emirates': '阿联酋',
          Argentina: '阿根廷',
          Armenia: '亚美尼亚',
          'French Southern and Antarctic Lands': '法属南半球和南极领地',
          Australia: '澳大利亚',
          Austria: '奥地利',
          Azerbaijan: '阿塞拜疆',
          Burundi: '布隆迪',
          Belgium: '比利时',
          Benin: '贝宁',
          'Burkina Faso': '布基纳法索',
          Bangladesh: '孟加拉国',
          Bulgaria: '保加利亚',
          'The Bahamas': '巴哈马',
          'Bosnia and Herzegovina': '波斯尼亚和黑塞哥维那',
          Belarus: '白俄罗斯',
          Belize: '伯利兹',
          Bermuda: '百慕大',
          Bolivia: '玻利维亚',
          Brazil: '巴西',
          Brunei: '文莱',
          Bhutan: '不丹',
          Botswana: '博茨瓦纳',
          'Central African Republic': '中非共和国',
          Canada: '加拿大',
          Switzerland: '瑞士',
          Chile: '智利',
          China: '中国大陆',
          'Ivory Coast': '象牙海岸',
          Cameroon: '喀麦隆',
          'Democratic Republic of the Congo': '刚果民主共和国',
          'Republic of the Congo': '刚果共和国',
          Colombia: '哥伦比亚',
          'Costa Rica': '哥斯达黎加',
          Cuba: '古巴',
          'Northern Cyprus': '北塞浦路斯',
          Cyprus: '塞浦路斯',
          'Czech Republic': '捷克共和国',
          Germany: '德国',
          Djibouti: '吉布提',
          Denmark: '丹麦',
          'Dominican Republic': '多明尼加共和国',
          Algeria: '阿尔及利亚',
          Ecuador: '厄瓜多尔',
          Egypt: '埃及',
          Eritrea: '厄立特里亚',
          Spain: '西班牙',
          Estonia: '爱沙尼亚',
          Ethiopia: '埃塞俄比亚',
          Finland: '芬兰',
          Fiji: '斐',
          'Falkland Islands': '福克兰群岛',
          France: '法国',
          Gabon: '加蓬',
          'United Kingdom': '英国',
          Georgia: '格鲁吉亚',
          Ghana: '加纳',
          Guinea: '几内亚',
          Gambia: '冈比亚',
          'Guinea Bissau': '几内亚比绍',
          Greece: '希腊',
          Greenland: '格陵兰',
          Guatemala: '危地马拉',
          'French Guiana': '法属圭亚那',
          Guyana: '圭亚那',
          Honduras: '洪都拉斯',
          Croatia: '克罗地亚',
          Haiti: '海地',
          Hungary: '匈牙利',
          Indonesia: '印度尼西亚',
          India: '印度',
          Ireland: '爱尔兰',
          Iran: '伊朗',
          Iraq: '伊拉克',
          Iceland: '冰岛',
          Israel: '以色列',
          Italy: '意大利',
          Jamaica: '牙买加',
          Jordan: '约旦',
          Japan: '日本',
          Kazakhstan: '哈萨克斯坦',
          Kenya: '肯尼亚',
          Kyrgyzstan: '吉尔吉斯斯坦',
          Cambodia: '柬埔寨',
          Kosovo: '科索沃',
          Kuwait: '科威特',
          Laos: '老挝',
          Lebanon: '黎巴嫩',
          Liberia: '利比里亚',
          Libya: '利比亚',
          'Sri Lanka': '斯里兰卡',
          Lesotho: '莱索托',
          Lithuania: '立陶宛',
          Luxembourg: '卢森堡',
          Latvia: '拉脱维亚',
          Morocco: '摩洛哥',
          Moldova: '摩尔多瓦',
          Madagascar: '马达加斯加',
          Mexico: '墨西哥',
          Macedonia: '马其顿',
          Mali: '马里',
          Myanmar: '缅甸',
          Montenegro: '黑山',
          Mongolia: '蒙古',
          Mozambique: '莫桑比克',
          Mauritania: '毛里塔尼亚',
          Malawi: '马拉维',
          Malaysia: '马来西亚',
          Namibia: '纳米比亚',
          'New Caledonia': '新喀里多尼亚',
          Niger: '尼日尔',
          Nigeria: '尼日利亚',
          Nicaragua: '尼加拉瓜',
          Netherlands: '荷兰',
          Norway: '挪威',
          Nepal: '尼泊尔',
          'New Zealand': '新西兰',
          Oman: '阿曼',
          Pakistan: '巴基斯坦',
          Panama: '巴拿马',
          Peru: '秘鲁',
          Philippines: '菲律宾',
          'Papua New Guinea': '巴布亚新几内亚',
          Poland: '波兰',
          'Puerto Rico': '波多黎各',
          'North Korea': '北朝鲜',
          Portugal: '葡萄牙',
          Paraguay: '巴拉圭',
          Qatar: '卡塔尔',
          Romania: '罗马尼亚',
          Russia: '俄罗斯',
          Rwanda: '卢旺达',
          'Western Sahara': '西撒哈拉',
          'Saudi Arabia': '沙特阿拉伯',
          Sudan: '苏丹',
          'South Sudan': '南苏丹',
          Senegal: '塞内加尔',
          'Solomon Islands': '所罗门群岛',
          'Sierra Leone': '塞拉利昂',
          'El Salvador': '萨尔瓦多',
          Somaliland: '索马里兰',
          Somalia: '索马里',
          'Republic of Serbia': '塞尔维亚',
          Suriname: '苏里南',
          Slovakia: '斯洛伐克',
          Slovenia: '斯洛文尼亚',
          Sweden: '瑞典',
          Swaziland: '斯威士兰',
          Syria: '叙利亚',
          Chad: '乍得',
          Togo: '多哥',
          Thailand: '泰国',
          Tajikistan: '塔吉克斯坦',
          Turkmenistan: '土库曼斯坦',
          'East Timor': '东帝汶',
          'Trinidad and Tobago': '特里尼达和多巴哥',
          Tunisia: '突尼斯',
          Turkey: '土耳其',
          'United Republic of Tanzania': '坦桑尼亚',
          Uganda: '乌干达',
          Ukraine: '乌克兰',
          Uruguay: '乌拉圭',
          'United States': '美国',
          Uzbekistan: '乌兹别克斯坦',
          Venezuela: '委内瑞拉',
          Vietnam: '越南',
          Vanuatu: '瓦努阿图',
          'West Bank': '西岸',
          Yemen: '也门',
          'South Africa': '南非',
          Zambia: '赞比亚',
          Korea: '韩国',
          Tanzania: '坦桑尼亚',
          Zimbabwe: '津巴布韦',
          Congo: '刚果',
          'Central African Rep.': '中非',
          Serbia: '塞尔维亚',
          'Bosnia and Herz.': '波黑',
          'Czech Rep.': '捷克',
          'W. Sahara': '西撒哈拉',
          'Lao PDR': '老挝',
          'Dem.Rep.Korea': '朝鲜',
          'Falkland Is.': '福克兰群岛',
          'Timor-Leste': '东帝汶',
          'Solomon Is.': '所罗门群岛',
          Palestine: '巴勒斯坦',
          'N. Cyprus': '北塞浦路斯',
          Aland: '奥兰群岛',
          'Fr. S. Antarctic Lands': '法属南半球和南极陆地',
          Mauritius: '毛里求斯',
          Comoros: '科摩罗',
          'Eq. Guinea': '赤道几内亚',
          'Guinea-Bissau': '几内亚比绍',
          'Dominican Rep.': '多米尼加',
          'Saint Lucia': '圣卢西亚',
          Dominica: '多米尼克',
          'Antigua and Barb.': '安提瓜和巴布达',
          'U.S. Virgin Is.': '美国原始岛屿',
          Montserrat: '蒙塞拉特',
          Grenada: '格林纳达',
          Barbados: '巴巴多斯',
          Samoa: '萨摩亚',
          Bahamas: '巴哈马',
          'Cayman Is.': '开曼群岛',
          'Faeroe Is.': '法罗群岛',
          'IsIe of Man': '马恩岛',
          Malta: '马耳他共和国',
          Jersey: '泽西',
          'Cape Verde': '佛得角共和国',
          'Turks and Caicos Is.': '特克斯和凯科斯群岛',
          'St. Vin. and Gren.': '圣文森特和格林纳丁斯'
        }
        let geoCoordMap = {
          阿富汗: [67.709953, 33.93911],
          安哥拉: [17.873887, -11.202692],
          阿尔巴尼亚: [20.168331, 41.153332],
          阿联酋: [53.847818, 23.424076],
          阿根廷: [-63.61667199999999, -38.416097],
          亚美尼亚: [45.038189, 40.069099],
          法属南半球和南极领地: [69.348557, -49.280366],
          澳大利亚: [133.775136, -25.274398],
          奥地利: [14.550072, 47.516231],
          阿塞拜疆: [47.576927, 40.143105],
          布隆迪: [29.918886, -3.373056],
          比利时: [4.469936, 50.503887],
          贝宁: [2.315834, 9.30769],
          布基纳法索: [-1.561593, 12.238333],
          孟加拉国: [90.356331, 23.684994],
          保加利亚: [25.48583, 42.733883],
          巴哈马: [-77.39627999999999, 25.03428],
          波斯尼亚和黑塞哥维那: [17.679076, 43.915886],
          白俄罗斯: [27.953389, 53.709807],
          伯利兹: [-88.49765, 17.189877],
          百慕大: [-64.7505, 32.3078],
          玻利维亚: [-63.58865299999999, -16.290154],
          巴西: [-51.92528, -14.235004],
          文莱: [114.727669, 4.535277],
          不丹: [90.433601, 27.514162],
          博茨瓦纳: [24.684866, -22.328474],
          中非共和国: [20.939444, 6.611110999999999],
          加拿大: [-106.346771, 56.130366],
          瑞士: [8.227511999999999, 46.818188],
          智利: [-71.542969, -35.675147],
          中国大陆: [104.195397, 35.86166],
          象牙海岸: [-5.547079999999999, 7.539988999999999],
          喀麦隆: [12.354722, 7.369721999999999],
          刚果民主共和国: [21.758664, -4.038333],
          刚果共和国: [15.827659, -0.228021],
          哥伦比亚: [-74.297333, 4.570868],
          哥斯达黎加: [-83.753428, 9.748916999999999],
          古巴: [-77.781167, 21.521757],
          北塞浦路斯: [33.429859, 35.126413],
          塞浦路斯: [33.429859, 35.126413],
          捷克共和国: [15.472962, 49.81749199999999],
          德国: [10.451526, 51.165691],
          吉布提: [42.590275, 11.825138],
          丹麦: [9.501785, 56.26392],
          多明尼加共和国: [-70.162651, 18.735693],
          阿尔及利亚: [1.659626, 28.033886],
          厄瓜多尔: [-78.18340599999999, -1.831239],
          埃及: [30.802498, 26.820553],
          厄立特里亚: [39.782334, 15.179384],
          西班牙: [-3.74922, 40.46366700000001],
          爱沙尼亚: [25.013607, 58.595272],
          埃塞俄比亚: [40.489673, 9.145000000000001],
          芬兰: [25.748151, 61.92410999999999],
          斐: [178.065032, -17.713371],
          福克兰群岛: [-59.523613, -51.796253],
          法国: [2.213749, 46.227638],
          加蓬: [11.609444, -0.803689],
          英国: [-3.435973, 55.378051],
          格鲁吉亚: [-82.9000751, 32.1656221],
          加纳: [-1.023194, 7.946527],
          几内亚: [-9.696645, 9.945587],
          冈比亚: [-15.310139, 13.443182],
          几内亚比绍: [-15.180413, 11.803749],
          赤道几内亚: [10.267895, 1.650801],
          希腊: [21.824312, 39.074208],
          格陵兰: [-42.604303, 71.706936],
          危地马拉: [-90.23075899999999, 15.783471],
          法属圭亚那: [-53.125782, 3.933889],
          圭亚那: [-58.93018, 4.860416],
          洪都拉斯: [-86.241905, 15.199999],
          克罗地亚: [15.2, 45.1],
          海地: [-72.285215, 18.971187],
          匈牙利: [19.503304, 47.162494],
          印尼: [113.921327, -0.789275],
          印度: [78.96288, 20.593684],
          爱尔兰: [-8.24389, 53.41291],
          伊朗: [53.688046, 32.427908],
          伊拉克: [43.679291, 33.223191],
          冰岛: [-19.020835, 64.963051],
          以色列: [34.851612, 31.046051],
          意大利: [12.56738, 41.87194],
          牙买加: [-77.297508, 18.109581],
          约旦: [36.238414, 30.585164],
          日本: [138.252924, 36.204824],
          哈萨克斯坦: [66.923684, 48.019573],
          肯尼亚: [37.906193, -0.023559],
          吉尔吉斯斯坦: [74.766098, 41.20438],
          柬埔寨: [104.990963, 12.565679],
          韩国: [127.766922, 35.907757],
          科索沃: [20.902977, 42.6026359],
          科威特: [47.481766, 29.31166],
          老挝: [102.495496, 19.85627],
          黎巴嫩: [35.862285, 33.854721],
          利比里亚: [-9.429499000000002, 6.428055],
          利比亚: [17.228331, 26.3351],
          斯里兰卡: [80.77179699999999, 7.873053999999999],
          莱索托: [28.233608, -29.609988],
          立陶宛: [23.881275, 55.169438],
          卢森堡: [6.129582999999999, 49.815273],
          拉脱维亚: [24.603189, 56.879635],
          摩洛哥: [-7.092619999999999, 31.791702],
          摩尔多瓦: [28.369885, 47.411631],
          马达加斯加: [46.869107, -18.766947],
          墨西哥: [-102.552784, 23.634501],
          马其顿: [21.745275, 41.608635],
          马里: [-3.996166, 17.570692],
          缅甸: [95.956223, 21.913965],
          黑山: [19.37439, 42.708678],
          蒙古: [103.846656, 46.862496],
          莫桑比克: [35.529562, -18.665695],
          毛里塔尼亚: [-10.940835, 21.00789],
          马拉维: [34.301525, -13.254308],
          马来西亚: [101.975766, 4.210484],
          纳米比亚: [18.49041, -22.95764],
          新喀里多尼亚: [165.618042, -20.904305],
          尼日尔: [8.081666, 17.607789],
          尼日利亚: [8.675277, 9.081999],
          尼加拉瓜: [-85.207229, 12.865416],
          荷兰: [5.291265999999999, 52.132633],
          挪威: [8.468945999999999, 60.47202399999999],
          尼泊尔: [84.12400799999999, 28.394857],
          新西兰: [174.885971, -40.900557],
          阿曼: [55.923255, 21.512583],
          巴基斯坦: [69.34511599999999, 30.375321],
          巴拿马: [-80.782127, 8.537981],
          秘鲁: [-75.015152, -9.189967],
          菲律宾: [121.774017, 12.879721],
          巴布亚新几内亚: [143.95555, -6.314992999999999],
          波兰: [19.145136, 51.919438],
          波多黎各: [-66.590149, 18.220833],
          北朝鲜: [127.510093, 40.339852],
          葡萄牙: [-8.224454, 39.39987199999999],
          巴拉圭: [-58.443832, -23.442503],
          卡塔尔: [51.183884, 25.354826],
          罗马尼亚: [24.96676, 45.943161],
          俄罗斯: [105.318756, 61.52401],
          卢旺达: [29.873888, -1.940278],
          西撒哈拉: [-12.885834, 24.215527],
          沙特阿拉伯: [45.079162, 23.885942],
          苏丹: [30.217636, 12.862807],
          南苏丹: [31.3069788, 6.876991899999999],
          塞内加尔: [-14.452362, 14.497401],
          所罗门群岛: [160.156194, -9.64571],
          塞拉利昂: [-11.779889, 8.460555],
          萨尔瓦多: [-88.89653, 13.794185],
          索马里兰: [46.8252838, 9.411743399999999],
          索马里: [46.199616, 5.152149],
          塞尔维亚共和国: [21.005859, 44.016521],
          苏里南: [-56.027783, 3.919305],
          斯洛伐克: [19.699024, 48.669026],
          斯洛文尼亚: [14.995463, 46.151241],
          瑞典: [18.643501, 60.12816100000001],
          斯威士兰: [31.465866, -26.522503],
          叙利亚: [38.996815, 34.80207499999999],
          乍得: [18.732207, 15.454166],
          多哥: [0.824782, 8.619543],
          泰国: [100.992541, 15.870032],
          塔吉克斯坦: [71.276093, 38.861034],
          土库曼斯坦: [59.556278, 38.969719],
          东帝汶: [125.727539, -8.874217],
          特里尼达和多巴哥: [-61.222503, 10.691803],
          突尼斯: [9.537499, 33.886917],
          土耳其: [35.243322, 38.963745],
          坦桑尼亚联合共和国: [34.888822, -6.369028],
          乌干达: [32.290275, 1.373333],
          乌克兰: [31.16558, 48.379433],
          乌拉圭: [-55.765835, -32.522779],
          美国: [-95.712891, 37.09024],
          乌兹别克斯坦: [64.585262, 41.377491],
          委内瑞拉: [-66.58973, 6.42375],
          越南: [108.277199, 14.058324],
          瓦努阿图: [166.959158, -15.376706],
          西岸: [35.3027226, 31.9465703],
          也门: [48.516388, 15.552727],
          南非: [22.937506, -30.559482],
          赞比亚: [27.849332, -13.133897],
          津巴布韦: [29.154857, -19.015438]
        }
        let seriesData = convertData(this.echartDatas)
        function convertData(data) {
          let res = []
          for (let i = 0; i < data.length; i++) {
            let geoCoord = geoCoordMap[data[i].name]
            if (geoCoord) {
              res.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value)
              })
            }
          }
          return res
        }
        // this.$refs.echartBar.removeAttribute("_echarts_instance_");
				// document.getElementById('echartLine').removeAttribute("_echarts_instance_");
				// 基于准备好的dom，初始化echarts实例
				let myEcharts = this.$echarts.init(this.$refs.myEcharts, 'light')
				// 指定图表的配置项和数据
				let option = {
					backgroundColor: "#FFFFFF",
          // title: {
          //   text: "全球专利布局",
          //   left: "40%",
          //   top: "0px",
          //   textStyle: {
          //     color: "#000000",
          //     opacity: 0.7
          //   }
          // },
          // dataRange: {
          //   show: false,
          //   min: 0,
          //   max: 1000000,
          //   text: ["High", "Low"],
          //   realtime: true,
          //   calculable: true,
          //   color: ["orangered", "yellow", "lightskyblue"],
          // },
          // tooltip: {
          //   formatter: function (params) {
          //     // console.log(params)
          //     // params.seriesName + "<br />" + params.name + "：" + params.value
          //     if(params.value) {
          //       return params.name + "：" + params.value;
          //     }
          //   }, //数据格式化
          // },
          geo: {
            map: "world",
            label: {
              formatter: function (params) { // 数据格式化
                // console.log(params)
                // params.seriesName + "<br />" + params.name + "：" + params.value
                if (params.value) {
                  return params.name + '：' + params.value
                }
              }
            },
            roam: false,
            zoom: 1.2,
            silent: true,
            center: [0, 20],
            itemStyle: {
              normal: {
                areaColor: "rgb(214, 218, 230)",
                borderColor: "rgb(247, 248, 250)"
              },
              emphasis: {
                areaColor: "rgb(214, 218, 230)"
              }
            }
          },
          series: [
            {
              show: true,
              type: 'scatter',
              // rippleEffect: { // 涟漪特效相关配置。
              //   scale: 4 // 控制涟漪大小
              // },
              symbolSize: 12,
              coordinateSystem: 'geo', // series坐标系类型
              data: seriesData, // 散点的坐标，自己定义一个var即可
              // data: convertData(dataList),
              itemStyle: {
                normal: {
                  color: 'rgb(105, 124, 236)'
                }
              }
            }
            // {
            //   type: "map",
            //   mapType: "world",
            //   zoom: 1.2,
            //   mapLocation: {
            //     y: 100
            //   },
            //   nameMap: nameMap, // nameMap映射
            //   data: dataList, // 数据国家名字
            //   symbolSize: 12,
            //   label: {
            //     formatter: function (params) {
            //       // console.log(params)
            //       // params.seriesName + "<br />" + params.name + "：" + params.value
            //       if (params.value) {
            //         return params.name + "：" + params.value
            //       }
            //     } // 数据格式化
            //   },
            //   itemStyle: {
            //     emphasis: {
            //       borderColor: "#fff",
            //       borderWidth: 1
            //     }
            //   }
            // }
          ]
				}
				// 使用刚指定的配置项和数据显示图表。
				myEcharts.setOption(option)
			}
		},
		watch: {
			echartDatas: {
				handler() { // val, oldVal
					// console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
					this.drawBar()
				}
			}
		}
	}
</script>

<style>
</style>
