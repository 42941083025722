<template>
  <!-- 智慧监管-网络监管-企业/院校-列表-柱状图 height: '35px' -->
  <div ref="echartBar" :style="{ margin: '0 auto', width: '30px', height: '100%' }"></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    echartColor: {
      type: Array,
      default() {
        return []
      }
    },
    echartDatas: {
      type: Object,
      default() {
        return {}
      }
    }
    // barColor: {
    //   type: String,
    //   default () {
    //     return ''
    //   }
    // }
  },
  components: {},
  created() {},
  mounted() {
    this.drawBar()
  },
  methods: {
    drawBar() {
      // this.$refs.echartBar.removeAttribute("_echarts_instance_");
      // document.getElementById('echartLine').removeAttribute("_echarts_instance_");
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(this.$refs.echartBar, 'light')
      let tempDatas = []
      /* 综合创新力 inno_comp
          创新产出力 inno_prod
          创新技术水平 inno_tech
          创新驱动力 inno_dynamic
          成果保护力 inno_prot
          成果转化力 inno_tran
          市场覆盖力 inno_mark
          行业影响力 inno_infl
          创新活跃度 inno_activity/inno_act */
      if (this.echartDatas) {
        for (let key in this.echartDatas) {
          if (key != 'inno_comp') {
            tempDatas.push({
              name: key,
              value: this.echartDatas[key]
            })
          }
          // if (key == 'inno_activity' || key == 'inno_act') {
          //   tempDatas.push({
          //     name: '创新活跃度', value: this.echartDatas[key]
          //   })
          // }
          // if (key == 'inno_dynamic') {
          //   tempDatas.push({
          //     name: '创新驱动力', value: this.echartDatas[key]
          //   })
          // }
          // if (key == 'inno_infl') {
          //   tempDatas.push({
          //     name: '行业影响力', value: this.echartDatas[key]
          //   })
          // }
          // if (key == 'inno_mark') {
          //   tempDatas.push({
          //     name: '市场覆盖力', value: this.echartDatas[key]
          //   })
          // }
          // if (key == 'inno_prod') {
          //   tempDatas.push({
          //     name: '创新产出力', value: this.echartDatas[key]
          //   })
          // }
          // if (key == 'inno_prot') {
          //   tempDatas.push({
          //     name: '成果保护力', value: this.echartDatas[key]
          //   })
          // }
          // if (key == 'inno_tech') {
          //   tempDatas.push({
          //     name: '创新技术水平', value: this.echartDatas[key]
          //   })
          // }
          // if (key == 'inno_tran') {
          //   tempDatas.push({
          //     name: '成果转化力', value: this.echartDatas[key]
          //   })
          // }
        }
      }
      // console.log(tempDatas)
      let yAxisDatas = []
      let seriesDatas = []
      for (let i = 0; i < tempDatas.length; i++) {
        yAxisDatas.push(tempDatas[i].name)
        seriesDatas.push(tempDatas[i].value)
      }
      // let barDatas = this.echartDatas
      // let barColor = 'rgba(5, 1, 98, 0.6)'
      // if (this.barColor) {
      //   barColor = this.barColor
      // }
      // // 指定图表的配置项和数据
      let option = {
        grid: {
          top: 0,
          bottom: 0
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          data: yAxisDatas
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            showBackground: false,
            itemStyle: {
              color: function (params) {
                let barColors = ['#036EB7', '#B61F22']
                if (params.dataIndex % 2 == 1) {
                  return barColors[0]
                }
                if (params.dataIndex % 2 == 0) {
                  return barColors[1]
                }
              }
            },
            barCategoryGap: 0,
            data: seriesDatas
            // data: [120, 200, 150, 80, 70, 110, 130]
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    }
  },
  watch: {
    echartDatas: {
      handler() {
        // val, oldVal
        // console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
        this.drawBar()
      }
    }
  }
}
</script>

<style>
</style>
