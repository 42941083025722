import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logininfo: {
      loginBloon: false,
      name: '',
      id: ''
    },
    // 用户角色
    userRole: '',
    // 是否展示登录框
    showLogin: false,
    // 商标列表q的参数
    brandQ: '',
    // 商标列表排序的参数
    brandSort: '',
    // 登录状态
    loginShow: false,
    // 用户名称及公司或角色名
    userHead: '',
    // 未读消息数量
    unRead: ''

  },
  getters: {
  },
  mutations: {
    logoChange(state, loginkeyarr) {
      // console.log(loginkeyarr)
      state.logininfo.loginBloon = loginkeyarr.loginBloon
      state.logininfo.name = loginkeyarr.frimName // 获取绑定的企业
      state.logininfo.id = loginkeyarr.id
    },
    // 展示登录框的方法
    changeShowLogin(state, status) {
      state.showLogin = status
    },
    // 修改用户信息
    upUserRole(state, status) {
      state.userRole = status
    },
    // 传商标q的参数
    sendBrandQ(state, q) {
      console.log(q, 'q')
      state.brandQ = q
    },
    sendBrandSort(state, sort) {
      state.brandSort = sort
    },
    // 修改登录状态
    upLoginShow(state, type) {
      state.loginShow = type
    },
    // 修改用户姓名-公司-角色名
    upUserHead(state, name) {
      state.userHead = name
    },
    // 修改未读消息数量
    upUnRead(state, num) {
      state.unRead = num
    }

  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage // 存储方式改为sessionStorage
  }
  )]
})
