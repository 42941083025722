import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/enterpriseDetail/enterpriseDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/enterpriseDetail/enterpriseDetail.vue')
  },
  {
    path: '/enterprise/report',
    name: 'reportGenerateOrg',
    component: () => import(/* webpackChunkName: "about" */ '../views/report/reportGenerateOrg.vue'),
    meta: {
      title: '企业/院校报告'
    }
  },
  {
    path: '/enterprise/detail',
    name: 'reportGenerateOrg',
    component: () => import(/* webpackChunkName: "about" */ '../views/enterpriseDetail/enterpriseDetail.vue'),
    meta: {
      title: '企业/院校详情'
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
